import react from 'react'


export const Logout = (props) => {        
    let token = props.token
    if (token === "") {
        window.location.href = "/"
    } else {
        fetch("https://backend.ornatemaritime.net/" + '/api/logout',{
          method: 'POST',          
          headers: {
              "Content-type": "application/json",
              "Authorization": "Token " + token
          }
    }).then(function(response){
          console.log(response)
          return ;
        })
        .then(() => {
           props.logout()           
        },
        err => {
          alert("Logout failed")          
          })
      }
      return("Logged out successfully")  
    }
    
