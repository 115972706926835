import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div>
            <h4 className="text-uppercase mb-4 text-center"><u>Privacy Policy</u></h4>
            <p className="lead mb-0 text-left" style={{ fontSize: '15px' }}>
                *These terms and conditions govern your use of our website and online marine courses. By accessing or using our services, you agree to be bound by these terms.*
            </p>
            <p className="lead mb-0 text-left" style={{ fontSize: '15px' }}>
                <strong><u>Privacy Policy</u></strong>: We collect and use your personal information in accordance with our Privacy Policy. We do not use your information for our own purposes. The information you share is only included in your certificate.
            </p>
            <p className="lead mb-0 text-left" style={{ fontSize: '15px' }}>
                <strong>1. Use of Services</strong>: You must be at least 18 years old to enroll in our courses. You agree to use our services only for lawful purposes and in accordance with these terms.
            </p>
            <p className="lead mb-0 text-left" style={{ fontSize: '15px' }}>
                <strong>2. Account and Enrollment  </strong>: When you create an account, you must provide accurate and complete information. You are responsible for maintaining the confidentiality of your account and password.
            </p>
            <p className="lead mb-0 text-left" style={{ fontSize: '15px' }}>
                <strong>3. Payment Terms</strong>: Payment for courses must be made through the methods provided on our website. Course fees are non-refundable.
            </p>
            <p className="lead mb-0 text-left" style={{ fontSize: '15px' }}>
                <strong>4. Course Content</strong>: All course materials are the intellectual property of ORNATE MARITIME PRIVATE LIMITED and are provided for your personal, non-commercial use only. You may not distribute, modify, or create derivative works without our written consent.
            </p>
            <p className="lead mb-0 text-left" style={{ fontSize: '15px' }}>
                <strong>6. Completion and Certification</strong>: Upon successful completion of a course, you will receive a certificate on your Registered email.
            </p>
            
            <p className="lead mb-0 text-left" style={{ fontSize: '15px' }}>
                <strong>8. Changes to Terms</strong>: We reserve the right to modify these terms at any time. Changes will be effective immediately upon posting on our website.
            </p>
            <p className="lead mb-0 text-left" style={{ fontSize: '16px' }}>If you have any questions or concerns about these terms, please contact us at <b><u>admin@ornatemaritime.net</u></b> or <b><u>+91-9004091006</u></b>.
            </p>
        </div>
    );
}

export default PrivacyPolicy;
