import React, { useState } from "react";
import {Form, Button, InputGroup} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Otp } from "./Otp";

export const VerifyForm = (props) => {
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [name, setName] = useState(""); 
    const [organization, setOrganization] = useState(""); 
    const [certificate_id, setCertificate] = useState(""); 
    const [verifying_entity, setVerifyingEntity] = useState(""); 
    const [err, setErr] = useState(null);
    const [eflag, seteflag] = useState(false)
    const [pflag, setpflag] = useState(false)
    const history = useHistory();

    const entity_types = ["","Individual","Organization"]

    
    const handleSubmit = (evt) => {        
        evt.preventDefault();        
        if (eflag && pflag) {
            fetch("https://backend.ornatemaritime.net/" + '/api/verification',{
            // fetch("http://127.0.0.1:8000/" + '/api/verification',{
            method: 'POST',
            body: JSON.stringify({
                name: name,
                phone: phone,
                email: email,
                verifying_entity: verifying_entity,
                certificate_id: certificate_id,
                organization: organization,
            }),
            headers: {
                "Content-type": "application/json"
            }
            }).then(function(response){
                console.log(response)
                return response.json();
            })
            .then(function(myJson) {
                alert("Certificate verification request processed successfully, result has been sent to your email.")     
                history.push("/");                              
            },
            err => {
                setErr(err)
                alert(err)
                }) 
        
        } else {
            alert("Email and/or Phone not verified")
            return
        }
    }    

    console.log("eflag :", eflag, "pflag: ", pflag, "email :", email, "phone :", phone)
       
    return(
        <div className="fluid-container"  id="verifyForm">
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicVerifyName">
                    <Form.Label className="required">Name</Form.Label>
                    <Form.Control type="text" autoFocus value={name} onChange={e => setName(e.target.value)}
                    maxLength="50" required placeholder="Enter name" />                            
                </Form.Group>

                <Form.Group controlId="formBasicVerifyEmail">
                    <Form.Label className="required">Email</Form.Label>
                    <InputGroup>
                    <Form.Control type="email" readOnly={eflag} value={email} onChange={e => setEmail(e.target.value)}
                    maxLength="100" required placeholder="Enter email" />
                     <InputGroup.Append>
                        <Otp otptype="email" otpkey={email} setVerified={seteflag}/>                        
                    </InputGroup.Append>
                    </InputGroup>                           
                </Form.Group>

                <Form.Group controlId="formBasicVerifyPhone">
                    <Form.Label className="required">Phone</Form.Label>
                    <InputGroup>
                    
                    <Form.Control type="number" readOnly={pflag} maxLength="10" required placeholder="Enter phone" value={phone} onChange={e => setPhone(e.target.value)} />                    
         
                     <InputGroup.Append>
                        <Otp otptype="phone" otpkey={phone} setVerified={setpflag}/>   
                    </InputGroup.Append>
                    </InputGroup> 

                </Form.Group>

                <Form.Group controlId="formBasicVerifyCertificate">
                    <Form.Label className="required">Certificate Number</Form.Label>
                    <Form.Control type="text"  value={certificate_id} onChange={e => setCertificate(e.target.value)}
                    maxLength="50" required placeholder="Enter Certificate Number" />                            
                </Form.Group>

                <Form.Group controlId="formBasicVerifyOrganization">
                    <Form.Label>Organization</Form.Label>
                    <Form.Control type="text"  value={organization} onChange={e => setOrganization(e.target.value)}
                    maxLength="100" placeholder="Enter organization name" />                            
                </Form.Group>

                <Form.Group controlId="formBasicVerifyEntity">
                    <Form.Label className="required">Verifying entity</Form.Label>
                    <Form.Control as="select" required name={verifying_entity} value={verifying_entity}  onChange={e => setVerifyingEntity(e.target.value)}>
                    
                    {entity_types.map((entity_type) => (
                        <option value={entity_type}>{entity_type}</option>                                
                    ))}                               
                    
                    </Form.Control>
                </Form.Group>
                <br/>
                <Button className="w-100" variant="info" type="submit">
                    Submit
                </Button>
            </Form>
            <br/>

            
        </div>
      
    )
}
