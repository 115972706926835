import React from 'react';
import './styles.css';

export const CourseFees = () => {
  const pdfFiles = [
    { id: 1, name: 'SMCP', link: '/pdfs/smcp.pdf', logo: '/pdfs/pdf.png' },
    // { id: 2, name: 'h2s course', link: '/pdfs/amalh2s.pdf', logo: '/pdfs/pdf_logo.png' },
  ];

  const videos = [
    { id: 1, title: 'Marlins Tutorial Video', link: 'https://www.youtube.com/playlist?list=PL5K0GG-JEG_uVBYxeAqzZ1qCi_dWc6RDb', logo: '/pdfs/video.png' },
    // { id: 2, title: 'Marlins Video 2', link: '/videos/video2.mp4', logo: '/pdfs/videoplayer.png' },
  ];

  return (
    <div className="container">
      <header>
        <div className="d-flex align-items-center ms-auto" style={{marginTop:"-90px"}}>
          <a href="#" className="navbar-brand fs-4"></a>
          <nav>
            <strong><a href=""></a></strong>
          </nav>
          <nav>
            <strong><a href=""></a></strong>
          </nav>
        </div>
        <div className="text-center w-75 my-4 mx-auto">
          <h1><u>Course Materials</u></h1>
        </div>
      </header>
      <div className="content" style = {{width: "750px", marginLeft:"150px"}}>
        <div className="sidebar">
          <h2><u>PDF Files</u></h2>
          <ul>
            {pdfFiles.map((pdf) => (
              <li key={pdf.id}>
                <a href={pdf.link} target="_blank" rel="noopener noreferrer">
                  <img src={pdf.logo} alt="PDF Logo" className="pdf-logo" />
                  {pdf.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="video-section">
          <h2><u>Tutorial Videos</u></h2>
          <ul>
            {videos.map((video) => (
              <li key={video.id}>
                <a href={video.link} target="_blank" rel="noopener noreferrer">
                  <img src={video.logo} alt="Video Logo" className="pdf-logo" />
                  {video.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CourseFees;
