import { PlaySection } from './PlaySection';
import { PlayVideo } from './PlayVideo';
import { useEffect } from 'react';

export const Sections = (props) => {
    let sections = props.sections;

    const playVideo = (e, section) => {
        e.preventDefault();
        let activeSection = sections.filter((sec) => sec.play === true)[0];
        if (activeSection) {
            activeSection.play = false;
            props.updateSection(activeSection);
        }
        if (section.section_ended && section.ended) {
            if (window.confirm("Chapter completed. Watch again?")) {
                section.currenturl = section.file;
            }
        }
        section.play = true;
        props.updateSection(section);
    };

    const stopVideo = (e, section) => {
        e.preventDefault();
        if (window.confirm("Stop " + section.course_title + " - " + section.title + "?")) {
            section.play = false;
            section.currenturl = section.url + '?start=' + section.played;
            props.updateSection(section);
            props.postActivity(section);
        }
    };

    const playEnd = (section) => {
        if (window.confirm(section.course_title + " - " + section.title + " completed. Close section?")) {
            section.play = false;
            section.ended = true;
            section.section_ended = true;
            section.status = "Completed";
            props.updateSection(section);
            props.postActivity(section);
        }
    };

    const updateProgress = (section, played) => {
        section.played = played;
        section.ended = false;
        section.status = "In-Progress";
        props.updateSection(section);
        if (played % 60 <= 2) {
            props.postActivity(section);
        }
    };

    useEffect(() => {
        console.log('props updated');
    });

    if (sections.length > 0) {
        return (
            <div className="green">
                <h3 className="text-info">{sections[0].course_title}</h3>
                <div className="row">
                    <div className="col-8">
                        <PlayVideo sections={sections} playEnd={playEnd} updateProgress={updateProgress} />
                    </div>
                    <div className="col-4 bg-primary">
                        <div className="header">
                            <div className="row"></div>
                        </div>
                        <ol className="playlist" id="playlist">
                            {sections.map((section, indx) => {
                                const status = section.status;
                                // Get first two words of the title followed by three dots
                                const shortTitle = section.title.split(' ').slice(0, 2).join(' ') + '...';
                                return status !== "" ? (
                                    <li key={indx} className="mt-3">
                                        <div className="row">
                                            <div className='col-9'>{shortTitle}<br /></div>
                                            <div className='col-3'>
                                                <PlaySection section={section} playVideo={playVideo} stopVideo={stopVideo} />
                                            </div>
                                            <div className='col-12' style={{ color: 'red' }}>
                                                <b><u>{section.status}</u></b>
                                            </div>
                                        </div>
                                    </li>
                                ) :
                                    ""
                            })}
                        </ol>
                    </div>
                </div>
            </div>
        )
    }
    return ""
}
