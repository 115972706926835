import {Button} from 'react-bootstrap'

export const LaunchCourse = (props) => {
    const enrollment = props.enrollment
    let course_description = enrollment.course_description
    let sections = props.currentSections
    //console.log("sections :" + sections)


    if (sections.length > 0) {
       
        if (enrollment.course == sections[0].course ) {       
            
            return(
                <Button  className="w-100 btn btn-info mb-2" type="submit" autoFocus onClick={(e) => {if(window.confirm("Stop " + course_description + "?")) {props.cancelLaunch(e)}}}>
                Cancel
                </Button>
            )  
    
          }  else {
                                    
            if (enrollment.payment_status !== "Pending" && enrollment.status !== "EXPIRED" && enrollment.status !== "CANCELLED") {
                
                if (sections.length > 0) {
                    return (
                    <Button  className="w-100 btn btn-info mb-2" type="submit" autoFocus onClick={(e) => {if(window.confirm("Stop " + sections[0].course_title + " and launch " + course_description + "?")) {props.launchCourse(e,enrollment)}}}>
                    Launch
                    </Button>
                    )
                } else {
                return (
                    <Button  className="w-100 btn btn-info mb-2" type="submit" autoFocus onClick={(e) => {if(window.confirm("Launch " + course_description + "?")) {props.launchCourse(e,enrollment)}}}>
                                    Launch
                    </Button>
                )
                }
            }  else {
                return(
                    <Button  className="w-100 btn btn-info mb-2" type="submit" disabled >
                    Launch
                    </Button>
                )       
            }
          }    
    }
       

    else if (enrollment.payment_status !== "Pending" && enrollment.status !== "EXPIRED") {
        return (
            <Button  className="w-100 btn btn-info mb-2" autoFocus type="submit" onClick={(e) => {if(window.confirm("Launch " + course_description + "?")) {props.launchCourse(e,enrollment)}}}>
                            Launch
            </Button>
        )
    } else {
        return(
            <Button  className="w-100 btn btn-info mb-2" autoFocus type="submit" disabled >
            Launch
            </Button>
        )       
    }
}