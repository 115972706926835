import Table from 'react-bootstrap/Table'
import { Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'

export const Exam = (props) => {


    useEffect(() => {
        console.log('props updated');
    })


    const exams = props.exams
    const canLaunch = (exam) => {
        if (exam.result != "Pass" && exam.remaining_attempts > 0) {
            return false
        }
        else {
            return true
        }
    }


    if (exams.length > 0) {
        return (
            <div>
                <h3 className="text-info">Available Exams</h3>
                <br />

                {exams.map((exam, indx) => (
                <div class="card overflow-hidden mb-2" key={indx}>
                    <div class="card-content">
                        <div class="card-body cleartfix">
                            <div className='row' >
                                <div className='col-lg-2 col-sm-12'>
                                    <p><span className='font-weight-bold'>Enroll Id: </span>{indx + 1}</p>
                                </div>
                                <div className='col-lg-8 col-sm-12'>
                                    <div className='row'>
                                        <div class="col-lg-6 col-sm-12 ps-3">
                                            <h5>{exam.examid}</h5>
                                            <p>{exam.duration_minutes} minutes</p>
                                        </div>
                                        <div class="col-lg-3 col-sm-12  ps-3">
                                            <p className='font-weight-bold'>Attemts Allowed</p>
                                            <p>{exam.maximum_allowed_attempts}</p>
                                        </div>
                                        <div class=" col-lg-3 col-sm-12 ps-3">
                                            <p className='font-weight-bold'>Attemts remained</p>
                                            <p>{exam.remaining_attempts}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-2 col-sm-12'>
                                <Button className="w-100 btn btn-info" type="submit" disabled={canLaunch(exam)} onClick={(e) => { if (window.confirm("Launch " + exam.examid + "?")) { props.launchTest(exam) } }}>
                                            Launch
                                        </Button>
                                    <div>
                                    {exam.result}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
))}

                {/* <div className="table-responsive">
                    <Table hover size="sm">
                        <thead>
                            <tr>
                                <th>Serial #</th>
                                <th>Id</th>
                                <th>Duration</th>
                                <th>Exam Result</th>
                                <th>Attempts allowed</th>
                                <th>Attempts remaining</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {exams.map((exam, indx) => (
                                <tr key={indx}>
                                    <td>
                                        {indx + 1}
                                    </td>
                                    <td>
                                        {exam.examid}
                                    </td>
                                    <td>
                                        {exam.duration_minutes} minutes
                                    </td>
                                    <td>
                                        {exam.result}
                                    </td>
                                    <td>
                                        {exam.maximum_allowed_attempts}
                                    </td>
                                    <td>
                                        {exam.remaining_attempts}
                                    </td>
                                    <td>
                                        <Button className="w-100 btn btn-info" type="submit" disabled={canLaunch(exam)} onClick={(e) => { if (window.confirm("Launch " + exam.examid + "?")) { props.launchTest(exam) } }}>
                                            Launch
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div> */}
                <br />
            </div>
        )
    } else {
        return ""
    }
}