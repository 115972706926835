import {Button} from 'react-bootstrap'

export const PlaySection = (props) => {
    let section = props.section
    
    
    if (section.play) {
        return (          
                <Button className="w-100 btn btn-secondary" type="submit" onClick={(e) => props.stopVideo(e,section)}>
                    Stop
                </Button>
        )

    } else {
        return (
            <Button className="w-100 btn btn-info" type="submit" onClick={(e) => {if(window.confirm("Play " + section.course_title + " - " + section.title + "?")) {props.playVideo(e,section)}}}>
                Play
            </Button>
        )
    }
}