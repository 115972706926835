import React, { useState, useEffect } from 'react'
import { Container, Table } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";

//import {Button} from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { Sections } from './components/sections'
import { Action } from './components/Action'
import { Exam } from './components/Exam'
import { Test } from './components/Test'
import { Enrollments } from './components/Enrollments'
import { Certificate } from './components/Certificate'


export const Courses = (props) => {
  const { courses, isLoggedIn, user, token, sections, setSections } = props;

  let [enrollments, setEnrollments] = useState([]);

  let [exams, setExams] = useState([]);

  let [test, setTest] = useState();

  let [showTest, setShowTest] = useState(false);

  const history = useHistory();

  let closeTest = () => {
    setShowTest(false)
  }

  let getExams = () => {

    if (exams.length === 0 && enrollments.length !== 0) {
      enrollments.map((enrollment, indx) => {
        if (enrollment.status === "COMPLETED") {
          fetch("https://backend.ornatemaritime.net/api/exam?course=" + enrollment.course, {
          // fetch("http://127.0.0.1:8000/" + "/api/exam?course=" + enrollment.course, {
            method: 'GET',
            headers: {
              "Authorization": `Token ${token}`
            }
          })
            .then(res => res.json())
            .then(
              (result) => {
                // console.log(result)
                result.map(exam => {
                  exam.enrollment = enrollment.id

                  exams = exams.concat(exam)
                  setExams(exams)
                  return
                })
                // console.log(exams)
              },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {
                // console.log(error)
              }
            )
          return
        }
      })
    }
  }

  let getEnrollments = () => {
    if (enrollments.length == 0) {
      if (props.isLoggedIn === true) {
        //fetch current enrollments
        fetch("https://backend.ornatemaritime.net/api/enrollment", {
        // fetch("http://127.0.0.1:8000/" + "/api/enrollment", {
          method: 'GET',
          headers: {
            "Authorization": `Token ${token}`
          }
        })
          .then(res => res.json())
          .then(
            (result) => {
              //console.log(result)
              setEnrollments(result)
              // console.log({enrollments})
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              // console.log(error)
            }
          )
      }
    }
  }



  const enroll = (e, item) => {
    e.preventDefault()

    fetch("https://backend.ornatemaritime.net/api/enrollment", {
    // fetch("http://127.0.0.1:8000/" + '/api/enrollment', {
      method: 'POST',
      headers: {
        "Content-type": "application/json",
        "Authorization": `Token ${token}`
      },
      body: JSON.stringify({
        course: item.id
      }),
    }).then(function (response) {

      // console.log(response)
      if (response.status === 200) {
        alert("Enrolled successfully")
      } else if (response.status === 409) {
        alert("Already enrolled")
      } else if (response.status === 401) {
        alert("Unauthorized - Please login")
      } else if (response.status === 403){
        alert("Enrollment request Pending Admin Approval")
      } else{
        alert("Enrollment request Pending Admin Approval.")
      }
      return response.json();
    })
      .then(
        (result) => {
          //  console.log(result)
          if (result["enrollment"]) {
            setEnrollments(enrollments.concat(result["enrollment"]))
          }
        },
        (error) => {
          //alert(error)
          // console.log(error)
        })
  }

  const cancelLaunch = (e) => {
    e.preventDefault()
    setSections([])
  }

  const launchCourse = (e, enrollment) => {
    let enrollment_id = enrollment.id
    if (enrollment.status === "CANCELLED") {
      alert("Enrollment not active")
      return
    }
    e.preventDefault()
    //fetch course sections
    fetch("https://backend.ornatemaritime.net/api/sections?course=" + enrollment.course, {
    // fetch("http://127.0.0.1:8000/" + "/api/sections?course=" + enrollment.course, {
      method: 'GET',
      headers: {
        "Authorization": `Token ${token}`
      }
    })
      .then((response) => {
        let res = response.json()
        //console.log(response)
        if (response.status === 200) {
          return res
        } else if (response.status === 404) {
          alert("Not found")
          return res
        } else if (response.status === 401) {
          alert("Unauthorized")
          return res
        }
      }
      )
      .then(
        (result) => {
          let resultStatus = result.map(section => {
            section.play = false
            fetch("https://backend.ornatemaritime.net/api/activity?section=" + section.id + "&enrollment=" + enrollment_id, {
            // fetch("http://127.0.0.1:8000/" + "/api/activity?section=" + section.id + "&enrollment=" + enrollment_id, {

              method: 'GET',
              headers: {
                "Authorization": `Token ${token}`
              }
            })
              .then((data) => {
                //let played = 0
                let res = data.json()
                if (data.status === 200) {
                  return res
                }
              }).then(
                (res) => {
                  section.status = res.status
                  section.played = res.played_decimal
                  section.section_ended = res.section_ended
                  section.ended = res.ended
                  section.enrollment = enrollment_id
                  section.play = false
                  section.currenturl = section.url + '?start=' + section.played
                  console.log("section.status: ", section.status)
                })
            return section
          })
          console.log("result status: ", resultStatus)
          setSections(resultStatus)
        },
        (error) => {
          //console.log(error)
        }
      )

  }

  const cancelEnrollment = (e, item) => {
    e.preventDefault()
    fetch("https://backend.ornatemaritime.net/api/enrollment", {
    // fetch("http://127.0.0.1:8000/" + '/api/enrollment', {
      method: 'DELETE',
      headers: {
        "Content-type": "application/json",
        "Authorization": `Token ${token}`
      },
      body: JSON.stringify({
        eid: item.eid,
        course: item.course
      }),
    }).then(function (response) {

      if (response.status === 200) {
        let enrollments_cancelled = enrollments.map((enrollment, indx) => {
          if (enrollment.eid === item.eid) {
            enrollment.status = "CANCELLED"
            return enrollment
          } else {
            return enrollment
          }
        })
        setEnrollments(enrollments_cancelled)
        if (sections.length > 0) {
          if (item.course === sections[0].course) {
            setSections([])
          }
        }
        alert("Enrolment Cancelled")
      } else if (response.status === 404) {
        alert("Enrollment not found")
      } else if (response.status === 401) {
        alert("Unauthorized - Please login")
        window.location.href = '/Login';
      } else if (response.status === 403) {
        alert("Cant cancel a paid enrollment")
        window.location.href = '/Login';
      }
      else
        alert("Error occurred")
      //console.log("enrollment response:" + response.status + response.statusText +  response.json) 
      return
    })
  }

  useEffect(() => {
    console.log("props updated")
    getEnrollments()
    getExams()
  });

  const updateSection = (section) => {
    let enrollment_status = "COMPLETED"
    if (section.section_ended === false) {
      enrollment_status = "IN-PROGRESS"
    }
    const updatedSections = sections.map(item => {
      if (item.id === section.id) {
        return section
      } else {
        if (item.section_ended === false) {
          enrollment_status = "IN-PROGRESS"
        }
        return item
      }
    })

    setSections(updatedSections)
    updateEnrollmentStatus(section.enrollment, enrollment_status)

    console.log("updateSection :" + sections)
  }

  let updateExams = (exam) => {
    let status = "PASSED"
    if (exam.result === "Fail") {
      status = "COMPLETED"
    }
    updateExams = exams.map(item => {
      if (item.id === exam.id) {
        return exam
      } else {
        if (item.result === "Fail" || item.result === "") {
          status = "COMPLETED"
        }
        return item
      }
    })
    setExams(updateExams)
    if (status === "PASSED") {
      updateEnrollmentStatus(exam.enrollment, status)
    }
  }

  let updateEnrollmentStatus = (enrollment, status) => {
    //alert("Update enrollment " + enrollment + ", " + status)
    const updateEnrollments = enrollments.map(item => {
      if (item.id === enrollment && item.status !== "PASSED") {
        if (status == "PASSED") {
          item.status = status
        }
        if (status == "COMPLETED" && item.status != "COMPLETED") {
          item.status = status
          setExams([])
          getExams()
        }
        return item
      } else {
        return item
      }
    })
    setEnrollments(updateEnrollments)
    //console.log("updateSection :" + sections)
  }

  let postActivity = (section) => {

    fetch("https://backend.ornatemaritime.net/api/activity", {
    // fetch("http://127.0.0.1:8000/" + '/api/activity', {
      method: 'POST',
      headers: {
        "Content-type": "application/json",
        "Authorization": `Token ${token}`
      },
      body: JSON.stringify({
        section: section.id,
        played_decimal: section.played,
        enrollment: section.enrollment,
        ended: section.ended,
        section_ended: section.section_ended
      }),
    }).then(function (response) {
      //console.log("Post activity section:" + section.played + section.ended + "response status :" + response.status)  
    })
  }

  const launchTest = (exam) => {
    if (window.confirm(exam.instructions)) {
      setSections([])
      setTest(exam)
      setShowTest(true)
    }
  }

  let submitTest = (test, images) => {
    console.log({ test })
    fetch("https://backend.ornatemaritime.net/api/test", {
    // fetch("http://127.0.0.1:8000/" + '/api/test', {
      method: 'POST',
      headers: {
        "Content-type": "application/json",
        "Authorization": `Token ${token}`
      },
      body: JSON.stringify({
        exam: test.id,
        enrollment: test.enrollment,
        submission: test.submission,
        seconds: test.seconds
      })
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log("TEST result log:", result)
          test.score = result.score
          test.result = result.result
          test.remaining_attempts = test.remaining_attempts - 1
          updateExams(test)
          if (test.result === "Pass") {
            alert("Congratulations you passed the exam :" + test.examid + ". Your score is " + test.score + "%")
          } else {
            alert("Sorry you couldn't pass the exam :" + test.examid + ". Your score is " + test.score + "%. You need at least " + test.pass_percentage + "% to pass the exam.")
          }

          images.map((image) => {
            fetch("https://backend.ornatemaritime.net/api/recording", {
            // fetch("http://127.0.0.1:8000/" + '/api/recording', {
              method: 'POST',
              headers: {
                "Content-type": "application/json",
                "Authorization": `Token ${token}`,
              },
              body: JSON.stringify({
                'picture': image,
                'test': result.id,
              }),
            }).then(res => res.json())
              .then(
                (result) => {
                  console.log(result)
                },
                (error) => {
                  alert("Error occurred" + error)
                })

          })
        },
        (error) => {
          alert("Error occurred processing test submission")
          console.log(error)
        })
    //console.log(test)
  }
  console.log("sections:", { sections })
  console.log("courses:", courses)
  console.log("enrollments", enrollments)
  console.log("exams:", exams)
  console.log("user:", user)
  //console.log("show  home:", showTest)
  //console.log("test home:" ,{test})

  let get_enrollment_status = (enrollment) => {

    if (enrollment.status === "PASSED") {
      return (<div>
        <h6>{enrollment.status} <span><Certificate enrollment={enrollment} token={token} /></span></h6>
      </div>)
    } else {
      return (enrollment.status)
    }
  }


  if (isLoggedIn === false) {
    return (
      <Container>
        <h2 className="text-info">Courses</h2>
        <br />
        <div className='row course_row'>
          {courses.map(item => (
            <div className='col-lg-3 col-sm-12 col-xs-12 my-3 stretch' key={item.id} scope="row">
              <div className="card course_card" >
                <div className="card-body">
                  <h5 className="card-title">{item.title}</h5>
                  <p className="card-text">{item.description}</p>
                </div>
                <div className='card_end_Sec card-footer'>
                  <div className='row card_end '>
                    <div className='col'>
                      <h6 className="card-subtitle mb-2 text-muted">Price: {item.price}</h6>
                      <h6 className="card-subtitle mb-2 text-muted">Duration: 1 Day</h6>
                    </div>
                    <div className='col text-end'>
                      <Link href="/Register" as={Link} to="/Login" className="btn btn-primary ">
                        Enroll
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    )
  } else {
    return (
      <div>
        <Container>
          <h2 className="text-info">Courses</h2>
          <br />
          <div className='row course_row'>
            {courses.map(item => (
              <div className='col-lg-3 col-sm-12 col-xs-12 my-3 stretch' key={item.id} scope="row">
                <div className="card course_card" >
                  <div className="card-body">
                    <h5 className="card-title">{item.title}</h5>
                    <p className="card-text">{item.description}</p>
                  </div>
                  <div className='card_end_Sec card-footer'>
                    <div className='row card_end'>
                      <div className='col'>
                        <h6 className="card-subtitle mb-2 text-muted">Price: {item.price}</h6>
                        <h6 className="card-subtitle mb-2 text-muted">Duration: 1 Day</h6>
                        {/* <h6 className="card-subtitle mb-2 text-muted">Remaining days: <span>{item.remaining_days}</span></h6> */}
                      </div>
                      <div className='col text-end'>
                        <Action course={item} enrollments={enrollments} enroll={enroll} user={user}
                          token={token} setEnrollments={setEnrollments} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <br />
          {enrollments.length > 0 && (
            <div>
              <Enrollments enrollments={enrollments} sections={sections} get_enrollment_status={get_enrollment_status} launchCourse={launchCourse} cancelEnrollment={cancelEnrollment} cancelLaunch={cancelLaunch} />
              <br />
            </div>
          )}
          {sections.length > 0 && (
            <div>
              <Sections sections={sections} updateSection={updateSection} postActivity={postActivity} />
              <br />
            </div>
          )}
          {exams.length > 0 && enrollments.length > 0 && (
            <div>
              <Exam exams={exams} launchTest={launchTest} />
              <br />
            </div>
          )}
          {test && (
            <Test test={test} token={token} submitTest={submitTest} showTest={showTest} closeTest={closeTest} />
          )}
        </Container>
      </div>
    )

  }

}
