import { Table } from 'react-bootstrap'

export const Documents = (props) => {
    let documents = props.documents
    
    return (
        <div>
            <br/>
             <h4 className="text-center text-info">Documents</h4>
             <Table responsive hover size="sm"  cellspacing="0" width="100%">
            <thead>
                <tr>
                    <th>ID TYPE</th>
                    <th>ID NUMBER</th>
                    <th>DOCUMENT</th>
                </tr>
            </thead>
            <tbody>
                {documents.map((document, indx) => (
                    
                    <tr key={indx}>
                        <td>{document.id_type}</td>                        
                        <td>{document.id_number}</td>                        
                        <td><img src={"https://backend.ornatemaritime.net/" + document.id_img} height="90px" width="100px" /></td>
                        {/* <td><img src={"http://127.0.0.1:8000/" + document.id_img} height="90px" width="100px" /></td> */}

                    </tr>
                ))}
            </tbody>
      </Table>
        </div>
       
    )
}