import {VerifyForm} from './components/VerifyForm';

export const Verify = (props) => {
    return (
            <div className="container">           
                <br/>
                 <h2 className="text-info">Welcome to certificate verification</h2>  
                <br/>
                <h6 className="text-info">Please submit below form to verify certificate</h6>   
              
                <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xm-12">
                        <VerifyForm generateOtp={props.generateOtp} verifyOtp={props.verifyOtp} resetOtp={props.resetOtp}/>
                    </div>
                </div>
            </div>
        )
    }