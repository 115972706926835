import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faPhoneSquareAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { AddressMap } from './components/GoogleMap'
import { Contactform } from './components/ContactForm';

export const Contact = (props) => (
    <div className="container-fluid">
        <h2 className="text-center text-info"><b><u>Contact Us</u></b></h2>
        <br/>
        <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xm-12 mb-5 pb-5 contact_box rounded"> 
                <br/>          
                <Contactform/>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 col-xm-12">
                <br/>
                <div className="container-fluid">
                    <FontAwesomeIcon icon={faAddressCard} size="2x" className="text-info"/>
                    <br/>                
                    <p>B- 602, The Great Eastern Summit, Plot-66, Sector-15, CBD Belapur, Navi Mumbai-400614, Maharashtra, India.</p>
                    <AddressMap className="text-info"/>
                    <br/>
                    <br/>
                </div>
            </div>
        </div>        
    </div>    
)

