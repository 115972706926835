import React, { useState } from 'react';
import axios from 'axios';

export const Action = (props) => {
    const [paymentError, setPaymentError] = useState(null);

    const handleRazorpayPayment = async () => {
        const { course, user, token } = props;

        const createOrder = async () => {
            try {
                const response = await axios.post('https://backend.ornatemaritime.net/api/create-order', {
                // const response = await axios.post('http://127.0.0.1:8000/api/create-order', {
                    amount: course.price * 100, // amount in paise (multiply by 100 to convert to rupees)
                    currency: 'INR',
                    receipt: `receipt_${course.id}_${new Date().getTime()}`, // unique receipt id
                }, {
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                return response.data;
            } catch (error) {
                console.error('Order creation error:', error);
                setPaymentError('Failed to create order. Please try again.');
                return null;
            }
        };

        const order = await createOrder();
        if (!order) return;

        const options = {
            key: 'rzp_live_9ZW7ZmGon2kGrn', // Enter the Key ID generated from the Dashboard
            amount: order.amount, // Amount is in currency subunits. Default currency is INR.
            currency: order.currency,
            name: 'Ornate Maritime Pvt Ltd',
            description: course.title,
            image: 'https://example.com/your_logo',
            order_id: order.id, // This is a sample Order ID. Pass the `id` obtained in the response of createOrder() API as the `order_id`
            handler: async (response) => {
                try {
                    const paymentResponse = await axios.post('https://backend.ornatemaritime.net/api/verify-payment', {
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_order_id: response.razorpay_order_id,
                        razorpay_signature: response.razorpay_signature,
                        course_id: course.id,
                    }, {
                        headers: {
                            'Authorization': `Token ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                    console.log('Payment Success:', paymentResponse.data);
                    alert('Payment Success');

                    // Fetch the updated enrollments
                    fetchEnrollments();

                } catch (error) {
                    console.error('Payment verification error:', error);
                    setPaymentError('Payment verification failed. Please try again.');
                }
            },
            prefill: {
                name: user.name,
                email: user.email,
                contact: user.phone,
            },
            notes: {
                address: 'Corporate Office',
            },
            theme: {
                color: '#3399cc',
            },
        };

        const rzp = new window.Razorpay(options);
        rzp.on('payment.failed', (response) => {
            console.error('Payment failed:', response.error);
            setPaymentError('Payment failed. Please try again.');
        });

        rzp.open();
    };

    const fetchEnrollments = async () => {
        try {
            const response = await axios.get('https://backend.ornatemaritime.net/api/enrollment', {
                headers: {
                    'Authorization': `Token ${props.token}`
                }
            });
            props.setEnrollments(response.data);
        } catch (error) {
            console.error('Error fetching enrollments:', error);
        }
    };

    const handleEnroll = async () => {
        if (props.course.price > 0) {
            await handleRazorpayPayment();
        } else {
            await enrollCourse();
        }
    };

    const enrollCourse = async () => {
        try {
            const response = await axios.post('https://backend.ornatemaritime.net/api/enrollment', {
                course: props.course.id,
            }, {
                headers: {
                    'Authorization': `Token ${props.token}`,
                    'Content-Type': 'application/json',
                },
            });
            console.log('Enrollment response:', response.data);
            alert('Enrolled Successfully!');

            // Fetch the updated enrollments
            fetchEnrollments();
        } catch (error) {
            console.error('Enrollment Error:', error);
            alert('Enrollment Failed. Please Try again.');
        }
    };

    return (
        <div>
            {props.course.price > 0 ? (
                <button className='btn btn-info' onClick={handleEnroll}>
                    Pay and Enroll
                </button>
            ) : (
                <button className='btn btn-info' onClick={handleEnroll}>
                    Enroll
                </button>
            )}
            {paymentError && <p className='text-danger'>{paymentError}</p>}
        </div>
    );
};
