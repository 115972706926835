import React from 'react';

 const AddressMap =() => (    
        <div className="google-map-code">
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7546.2053878117395!2d73.0130714226119!3d18.97107154469713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1she%20Atlantis%2C%20Unit%20No-902%2C%20Sector-09%2C%20Ulwe%2C%20Raigad%2C%20Maharashtra%20%E2%80%93%20410206.!5e0!3m2!1sen!2sin!4v1624609857729!5m2!1sen!2sin" width="100%" height="350" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.2154521328!2d73.0331506148503!3d19.010225487125716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3ae85e8d0e1%3A0xe061c90ba86ea03f!2sThe%20Great%20Eastern%20summit%20CBD%20belapur%20Navi%20Mumbai!5e0!3m2!1sen!2sin!4v1675231877098!5m2!1sen!2sin" width="100%" height="350" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        
    )

 export { AddressMap }