//import react from 'react'
import {RegisterForm} from './components/RegisterForm';


export const Register = (props) => {
    return(
        <div className="container">           
            <br/>
            <h2 className="text-info">Please signup for new account</h2>   
            <br/>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xm-12">
                    <RegisterForm setToken={props.setToken}/>
                </div>
            </div>
        </div>
    )
}