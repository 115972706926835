import Table from 'react-bootstrap/Table'
import { LaunchCourse } from './LaunchCourse'
import { EnrollmentAction } from './EnrollmentAction'
import { useEffect } from 'react'
import { Nav, Tab, Tabs } from 'react-bootstrap';

export const Enrollments = (props) => {
    const enrollments = props.enrollments
    // console.log("enrollemnts in enrollments :", enrollments)

    useEffect(() => {
        console.log('props updated');
    })


    if (enrollments.length > 0) {

        return (
            <div>
                <h3 className="text-info">Current Enrollments</h3>
                <br />
                <Tabs defaultActiveKey="enrolled">
                    {/* <Tab eventKey="all" title="All">
                        <div class=" col-md-12 px-0 py-2">
                            {enrollments.map((enrollment, indx) => {
                                return enrollment.status !== "CANCELLED" ?
                                    (

                                        <div class="card overflow-hidden mb-2">
                                            <div class="card-content">
                                                <div class="card-body cleartfix">
                                                    <div className='row' key={enrollment.id}>
                                                        <div className='col-lg-1 col-sm-12'>
                                                            <p><span className='font-weight-bold'>Enroll Id: </span>{enrollment.eid}</p>
                                                    <p><span className='font-weight-bold'>Course Id: </span>{enrollment.course}</p>
                                                            <p><span className='font-weight-bold'>{indx + 1}</span></p>

                                                        </div>
                                                        <div className='col-lg-9 col-sm-12'>
                                                            <div className='row'>
                                                                <div class="col-lg-6 col-sm-12 ps-3">
                                                                    <h5>{enrollment.course_description}</h5>
                                                                    <p>{enrollment.start_date}</p>
                                                                </div>
                                                                <div class="col-lg-3 col-sm-12  ps-3">
                                                                    <p className='font-weight-bold'>Status</p>
                                                                    <p>{props.get_enrollment_status(enrollment)}</p>
                                                                </div>
                                                                <div class=" col-lg-3 col-sm-12 ps-3">
                                                                    <p className='font-weight-bold'>Payment Status</p>
                                                                    <p>{enrollment.payment_status}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-2 col-sm-12'>
                                                            <LaunchCourse enrollment={enrollment} launchCourse={props.launchCourse} currentSections={props.sections} cancelLaunch={props.cancelLaunch} />
                                                            <br />
                                                            <EnrollmentAction enrollment={enrollment} cancelEnrollment={props.cancelEnrollment} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    ) :
                                    ""
                            })}
                        </div>
                    </Tab> */}
                    <Tab eventKey="enrolled" title="Enrolled">
                    <div class=" col-md-12 px-0 py-2">
                    {enrollments.map((enrollment, indx) => {
                        return enrollment.status !== "CANCELLED" && enrollment.status == "ENROLLED" ?
                            (
                                <div>
                                    <div class="card overflow-hidden mb-2">

                                        <div class="card-content">
                                            <div class="card-body cleartfix">
                                                <div className='row' key={enrollment.id}>
                                                    <div className='col-lg-1 col-sm-12'>
                                                        {/* <p><span className='font-weight-bold'>Enroll Id: </span>{enrollment.eid}</p>
                                                    <p><span className='font-weight-bold'>Course Id: </span>{enrollment.course}</p> */}
                                                        <p><span className='font-weight-bold'>{indx + 1}</span></p>

                                                    </div>
                                                    <div className='col-lg-9 col-sm-12'>
                                                        <div className='row'>
                                                            <div class="col-lg-6 col-sm-12 ps-3">
                                                                <h5>{enrollment.course_description}</h5>
                                                                <p>{enrollment.start_date}</p>
                                                            </div>
                                                            <div class="col-lg-3 col-sm-12  ps-3">
                                                                <p className='font-weight-bold'>Status</p>
                                                                <p>{props.get_enrollment_status(enrollment)}</p>
                                                            </div>
                                                            <div class=" col-lg-3 col-sm-12 ps-3">
                                                                <p className='font-weight-bold'>Payment Status</p>
                                                                <p>{enrollment.payment_status}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-2 col-sm-12'>
                                                        <LaunchCourse enrollment={enrollment} launchCourse={props.launchCourse} currentSections={props.sections} cancelLaunch={props.cancelLaunch} />
                                                        <br />
                                                        <EnrollmentAction enrollment={enrollment} cancelEnrollment={props.cancelEnrollment} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) :
                            ""
                    })}
                </div>
                    </Tab>
                    <Tab eventKey="inProgress" title="In-Progress">
                    <div class=" col-md-12 px-0 py-2">
                    {enrollments.map((enrollment, indx) => {
                        return enrollment.status !== "CANCELLED" && enrollment.status == "IN-PROGRESS" ?
                            (
                                <div>

                                    <div class="card overflow-hidden mb-2">

                                        <div class="card-content">
                                            <div class="card-body cleartfix">
                                                <div className='row' key={enrollment.id}>
                                                    <div className='col-lg-1 col-sm-12'>
                                                        {/* <p><span className='font-weight-bold'>Enroll Id: </span>{enrollment.eid}</p>
                                                    <p><span className='font-weight-bold'>Course Id: </span>{enrollment.course}</p> */}
                                                        <p><span className='font-weight-bold'>{indx + 1}</span></p>

                                                    </div>
                                                    <div className='col-lg-9 col-sm-12'>
                                                        <div className='row'>
                                                            <div class="col-lg-6 col-sm-12 ps-3">
                                                                <h5>{enrollment.course_description}</h5>
                                                                <p>{enrollment.start_date}</p>
                                                            </div>
                                                            <div class="col-lg-3 col-sm-12  ps-3">
                                                                <p className='font-weight-bold'>Status</p>
                                                                <p>{props.get_enrollment_status(enrollment)}</p>
                                                            </div>
                                                            <div class=" col-lg-3 col-sm-12 ps-3">
                                                                <p className='font-weight-bold'>Payment Status</p>
                                                                <p>{enrollment.payment_status}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-2 col-sm-12'>
                                                        <LaunchCourse enrollment={enrollment} launchCourse={props.launchCourse} currentSections={props.sections} cancelLaunch={props.cancelLaunch} />
                                                        <br />
                                                        <EnrollmentAction enrollment={enrollment} cancelEnrollment={props.cancelEnrollment} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) :
                            ""
                    })}
                </div>
                    </Tab>
                    <Tab eventKey="completed" title="Completed">
                    <div class=" col-md-12 px-0 py-2">
                    {enrollments.map((enrollment, indx) => {
                        return enrollment.status !== "CANCELLED" && enrollment.status == "COMPLETED" ?
                            (
                                <div>

                                    <div class="card overflow-hidden mb-2">

                                        <div class="card-content">
                                            <div class="card-body cleartfix">
                                                <div className='row' key={enrollment.id}>
                                                    <div className='col-lg-1 col-sm-12'>
                                                        {/* <p><span className='font-weight-bold'>Enroll Id: </span>{enrollment.eid}</p>
                                                    <p><span className='font-weight-bold'>Course Id: </span>{enrollment.course}</p> */}
                                                        <p><span className='font-weight-bold'>{indx + 1}</span></p>

                                                    </div>
                                                    <div className='col-lg-9 col-sm-12'>
                                                        <div className='row'>
                                                            <div class="col-lg-6 col-sm-12 ps-3">
                                                                <h5>{enrollment.course_description}</h5>
                                                                <p>{enrollment.start_date}</p>
                                                            </div>
                                                            <div class="col-lg-3 col-sm-12  ps-3">
                                                                <p className='font-weight-bold'>Status</p>
                                                                <p>{props.get_enrollment_status(enrollment)}</p>
                                                            </div>
                                                            <div class=" col-lg-3 col-sm-12 ps-3">
                                                                <p className='font-weight-bold'>Payment Status</p>
                                                                <p>{enrollment.payment_status}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-2 col-sm-12'>
                                                        <LaunchCourse enrollment={enrollment} launchCourse={props.launchCourse} currentSections={props.sections} cancelLaunch={props.cancelLaunch} />
                                                        <br />
                                                        <EnrollmentAction enrollment={enrollment} cancelEnrollment={props.cancelEnrollment} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) :
                            ""
                    })}
                </div>
                    </Tab>
                    <Tab eventKey="passed" title="Passed">
                    <div class=" col-md-12 px-0 py-2">
                    {enrollments.map((enrollment, indx) => {
                        return enrollment.status !== "CANCELLED" && enrollment.status == "PASSED" ?
                            (
                                <div>

                                    <div class="card overflow-hidden mb-2">

                                        <div class="card-content">
                                            <div class="card-body cleartfix">
                                                <div className='row' key={enrollment.id}>
                                                    <div className='col-lg-1 col-sm-12'>
                                                        {/* <p><span className='font-weight-bold'>Enroll Id: </span>{enrollment.eid}</p>
                                                    <p><span className='font-weight-bold'>Course Id: </span>{enrollment.course}</p> */}
                                                        <p><span className='font-weight-bold'>{indx + 1}</span></p>
                                                    </div>
                                                    <div className='col-lg-9 col-sm-12'>
                                                        <div className='row'>
                                                            <div class="col-lg-6 col-sm-12 ps-3">
                                                                <h5>{enrollment.course_description}</h5>
                                                                <p>{enrollment.start_date}</p>
                                                            </div>
                                                            <div class="col-lg-3 col-sm-12  ps-3">
                                                                <p className='font-weight-bold'>Status</p>
                                                                <p>{props.get_enrollment_status(enrollment)}</p>
                                                            </div>
                                                            <div class=" col-lg-3 col-sm-12 ps-3">
                                                                <p className='font-weight-bold'>Payment Status</p>
                                                                <p>{enrollment.payment_status}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-2 col-sm-12'>
                                                        <LaunchCourse enrollment={enrollment} launchCourse={props.launchCourse} currentSections={props.sections} cancelLaunch={props.cancelLaunch} />
                                                        <br />
                                                        <EnrollmentAction enrollment={enrollment} cancelEnrollment={props.cancelEnrollment} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) :
                            ""
                    })}
                </div>
                    </Tab>
                </Tabs>


                {/* <div className='enrolled_table'>
                    <Table hover size="sm" responsive cellspacing="0" width="100%" className='rounded border border-dark '>
                        <thead >
                            <tr>
                                <th>Enrollment Id</th>
                            <th>Course Id</th>
                                <th>Id</th>
                                <th>Title</th>
                                <th>Start Date</th>
                                <th>Status</th>
                                <th>Payment Status</th>
                                <th>Launch Course</th>
                                <th>Cancel Enrollment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {enrollments.map((enrollment, indx) => {
                                return enrollment.status !== "CANCELLED" ?
                                    (
                                        <tr key={enrollment.id} className='py-3'>
                                            <td className='align-middle'>
                                            {enrollment.eid}
                                        </td>
                                        <td className='align-middle'>
                                            {enrollment.course}
                                        </td>
                                            <td className='align-middle'>
                                                {indx + 1}
                                            </td>
                                            <td className='align-middle'>
                                                {enrollment.course_description}
                                            </td>
                                            <td className='align-middle'>
                                                {enrollment.start_date}
                                            </td>
                                            <td className='align-middle'>
                                                {props.get_enrollment_status(enrollment)}
                                            </td>
                                            <td className='align-middle'>
                                                {enrollment.payment_status}
                                            </td>
                                            <td className='py-2'>
                                                <LaunchCourse enrollment={enrollment} launchCourse={props.launchCourse} currentSections={props.sections} cancelLaunch={props.cancelLaunch} />
                                            </td>
                                            <td className='py-2'>
                                                <EnrollmentAction enrollment={enrollment} cancelEnrollment={props.cancelEnrollment} />
                                            </td>
                                        </tr>
                                    ) :
                                    ""
                            })}
                        </tbody>
                    </Table>
                </div> */}
            </div>
        )

    }
    else {
        return "Loading"
    }


}