import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Nav, Navbar} from 'react-bootstrap'
import styled from 'styled-components'
import { NavLink } from "react-router-dom";


const Styles = styled.div`
  .navbar {
    background-color: #222;
    
  }
  a, .navbar-brand, .navbar-nav .nav-link {
    color: #bbb;
    &:hover {
      color: white;    }
    
  }  
`;

export const NavigationBar = (props) => {
  const isLoggedIn = props.isLoggedIn;
  const user = props.user;
  if (isLoggedIn) {
    return (
          <Navbar collapseOnSelect expand="lg">
            <Container>
          <Navbar.Brand href="/">Ornate Maritime Pvt Ltd</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">              
              <Nav.Item>
                <Nav.Link>
                  <Link to="/">Home</Link>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link>
                  <Link to="/courses">Courses</Link>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link>
                  <Link to="/about">About</Link>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link>
                  <Link to="/contact">Contact</Link>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link>
                  <Link to="/logout">Logout</Link>
                </Nav.Link>
              </Nav.Item>                                
            </Nav>
          </Navbar.Collapse>
          </Container>
        </Navbar>

      )
    }
  return(  
   
      <nav className="navbar navbar-expand-lg bg-secondary text-uppercase fixed-top" id="mainNav">
            <div className="container">
                    
                    <a class="navbar-brand" href="/">Ornate</a>
                    <button className="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button" 
                    data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                     aria-label="Toggle navigation">      Menu
                        <i className="fas fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ms-auto">
                        <li className="nav-item mx-0 mx-lg-1"><NavLink className="nav-link py-3 px-0 px-lg-3 rounded" to="/" exact>Home</NavLink></li>
                            <li className="nav-item mx-0 mx-lg-1"><NavLink  className="nav-link py-3 px-0 px-lg-3 rounded" to="/courses">Courses</NavLink></li>
                            <li className="nav-item mx-0 mx-lg-1"><NavLink  className="nav-link py-3 px-0 px-lg-3 rounded" to="/contact">Contact</NavLink></li>                            
                            <li className="nav-item mx-0 mx-lg-1"><NavLink className="nav-link py-3 px-0 px-lg-3 rounded" to="/login">Login</NavLink></li>
                          </ul>
                    </div>
                </div>
      </nav>
    
  )  
}

