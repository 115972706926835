import ReactPlayer from 'react-player'
import { useState, useRef } from 'react'
import { Modal, Button } from 'react-bootstrap';

export const PlayVideo = (props) => {

   const inputRange = useRef(null);

    
    //let sections = props.sections
    let section = props.sections.filter(section => section.play === true)[0]   
    
    const [playing, setPlaying] = useState(true);
    const [seeking, setSeeking] = useState(false);
  
    //const [loaded, setLoaded] = useState(0);
    const [duration, setDuration] = useState(0);
    const [played, setPlayed] = useState(0);
  
    //console.log('---> playing', playing);
    //console.log('---> seeking', seeking);
    //console.log('---> played', played);
    //console.log('---> duration', duration);
    

    const handlePlayPause = () => {
        setPlaying(!playing)
    }

    const handleEnded = () => {
        console.log('onEnded')
        setPlaying(false)
        props.playEnd(section,played.toFixed(2))
    }

    const handlePlay = () => {
        setPlaying(true)
      }
    
    const handlePause = () => {
        console.log('onPause')
        setPlaying(false)
        props.updateProgress(section,played.toFixed(2))
    }
    
       
    const handleProgress = (progress) => {
        console.log('handleProgress')
        if (!seeking) {
          setPlayed(progress.playedSeconds);
          props.updateProgress(section,progress.playedSeconds.toFixed(2))
        }        
    }
    
    
    const handleSeekMouseDown = e => {
        setSeeking(true)
    }

    //const handleSeekChange = e => {
    //    setPlayed(parseFloat(e.target.value));
    //  }
    
    
    //const handleSeekMouseUp = e => {
    //    console.log('***** e.target.value', e.target.value);
    //    setSeeking(false);
    //    inputRange.current.seekTo(parseFloat(e.target.value));
    //}

    const handleDuration = (duration) => {
        console.log('onDuration', duration);
        setDuration(duration);
      }
    
    
    
    if (section) {
        
        return(
        	<div>
            <h2 className="text-left text-info">Playing Course: {section.course_title} - {section.title}</h2>
            <br/>
            <ReactPlayer                    
                    ref={rp => this.rp = rp}
                    // Disable right click
                    onContextMenu={e => e.preventDefault()}
                    ref={inputRange}
                    playing={playing}                    
                    url={section.currenturl}
                    controls={true}
                    volume={1}
                    loop={false}
                    onProgress={handleProgress}
                    onDuration={handleDuration}
                    onPlay={handlePlay}
                    onSeek={e => console.log('onSeek', e)}                   
                    className='react-player'                    
                    width={'100%'}
                    
                    onEnded={handleEnded}  
                    onPause={handlePause}    
                                               
                />

              
            </div>
        )
    } else {
        return ""
    }

}
