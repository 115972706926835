import React, { useState, useEffect } from 'react'
import { Form, Button, Table , Container} from 'react-bootstrap'
import {Documents} from './Documents'
import {EditProfile} from './EditProfile'

export const Profile = (props) => {
    const token = props.token
    const [dob, setDob] = useState(null);    
    const [picture, setPicture] = useState(null);  
    const [phone, setPhone]  = useState("");    
    const [streetAddress, setstreetAddress] = useState(""); 
    const [city, setCity] = useState("");
    const [addressState, setAddressState] = useState("");
    const [pin, setPinCode] = useState("");
    const [cdc, setCdc] = useState("");
    const [passport, setPassport] = useState("");
    const [grade, setGrade] = useState("-----");
    const [indos, setIndos] = useState("");
    const [signature, setSignature] =  useState(null);
    const [documents, setDocuments] = useState(null);
    const [verified, setVerified] = useState(false);
    const [response, setResponse] = useState(null);
    const [editProfile, setEditProfile] = useState(false);
    
   

  const getProfile = () => {
    
        fetch("https://backend.ornatemaritime.net/" + "/api/customer",{
        // fetch("http://127.0.0.1:8000/" + "/api/customer",{
          method: 'GET',          
          headers: {            
              "Authorization": `Token ${token}` 
          }})
        .then(res => res.json())
        .then(
          (result) => {
            
            //console.log(result)
            setResponse(result)
            setPhone(result.phone)
            setDob(result.date_of_birth)
            setPicture(result.picture)
            setstreetAddress(result.street_address)
            setCity(result.city)
            setCdc(result.cdc_number)
            setPassport(result.passport_number)
            setGrade(result.grade_number || "-----")
            setIndos(result.indos_number)
            setSignature(result.signature)
            setDocuments(result.documents)            
            setPinCode(result.pin_code)
            setAddressState(result.state)
            setDocuments(result.customerids)
            
            if (result.verified === true) {                
                setVerified(true)
            } else {
                setVerified(false)
            }
            
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
           // console.log(error)
          }
        )
      }
  
    
  useEffect(() => {    
    getProfile()    
   },[]);

   const getVerified = () => {
       
       if (verified === true) {
           return "Yes"
       } else {
           return "No"
       }
   }   

   const getDocuments = () => {
       if (documents) {
        return documents
       } else 
       return []       
   }

   const getResponse = () => {
        if (response) {
        return response
        } else 
        return null       
    }

   const updateProfile = () => {
       setEditProfile(true)
   }

   const cancelUpdateProfile = () => {
    setEditProfile(false)
    getProfile()
   }

   
   //console.log("documents:", documents)

   if (editProfile) {
       return (
           <div>
               <h2 className="text-center text-info">Edit Profile</h2>
               <br/>

                <EditProfile response={getResponse()} token={token} cancelUpdateProfile={cancelUpdateProfile}/>  
           </div>
       )
   } else {
    return(
        <div className="container">
             <h2 className="text-center text-info">Manage Profile</h2>
             
             <br/>
             <div className="row">
                  <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                      <Container fluid>
                          <br/>
                        <h4 className="text-center text-info"><u>Personal Details</u></h4>
                        <Table responsive hover size="sm"  cellspacing="0" width="100%">
                            <tbody>
                                <tr>
                                    <th>Photo</th>
                                    <td><img src={"https://backend.ornatemaritime.net/" + picture} height="90px" width="100px" /></td>
                                    {/* <td><img src={"http://127.0.0.1:8000/" + picture} height="90px" width="100px" /></td> */}

                                </tr>
                                <tr>
                                    <th>Phone Number</th>
                                    <td>{phone}</td>
                                </tr>
                                <tr>
                                    <th>Date of birth</th>
                                    <td>{dob}</td>
                                </tr>
                                <tr>
                                    <th>Street Address</th>
                                    <td>{streetAddress}</td>
                                </tr>
                                <tr>
                                    <th>City</th>
                                    <td>{city}</td>
                                </tr>                                
                                <tr>
                                    <th>State</th>
                                    <td>{addressState}</td>
                                </tr>
                                <tr>
                                    <th>PIN Code</th>
                                    <td>{pin}</td>
                                </tr>
                                <tr>
                                    <th>Passport Number</th>
                                    <td>{passport}</td>
                                </tr>
                                <tr>
                                    <th>CDC Number</th>
                                    <td>{cdc}</td>
                                </tr>
                                <tr>
                                    <th>Grade NO./COC NO.</th>
                                    <td>{grade}</td>
                                </tr>
                                <tr>
                                    <th>INDOS</th>
                                    <td>{indos}</td>
                                </tr>
                                <tr>
                                    <th>Signature</th>
                                    <td><img src={"https://backend.ornatemaritime.net/" + signature} height="90px" width="100px" /></td>
                                    {/* <td><img src={"http://127.0.0.1:8000/" + signature} height="90px" width="100px" /></td> */}

                                </tr>
                                <tr>
                                    <th>Verified</th>
                                    <td>{getVerified()}</td>
                                </tr>
                            </tbody>
                        </Table>  
                        </Container> 
                  </div>
            
                <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                     <Container fluid> 
                        <Documents documents={getDocuments()}/>   
                    </Container>                  
                </div>
    
             </div>
             <Button className="btn btn-info w-100 text-center" type="submit" 
                onClick={updateProfile}>
                Edit Profile
            </Button>
            <br/>
            <br/>

        </div>   
        )
   }
}