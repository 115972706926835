import React from 'react';
import { BrowserRouter as Router, Route,Switch } from 'react-router-dom';
import { Courses } from './Courses';
import { About } from './About';
import { Contact } from './Contact';
import { Verify } from './Verify';
import { NoMatch } from './NoMatch';
import { Layout } from './components/Layout';
import { NavigationBar } from './components/Navigation';
import { Jumbotron } from './components/Jumbotron';
import { Login } from './Login';
import { Logout } from './Logout';
import { Register } from './Register';
import { Home } from './Home';
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { Profile } from './components/Profile'
import { Success } from './components/Success'
import { Error } from './components/Error'
import { CourseFees } from './components/CourseFees';
export default class App extends React.Component {
  constructor(props) {
    super(props); 

    this.state = {
      error: null,
      isLoaded: false,      
      courses: [],    
      sections: [],  
      token: "",
      isLoggedIn: false,
      user: "",      
    };

    this.setCourses()

  }


  setUser = (user) => {
    this.setState({user: user})    
  }
  
  setToken = (token) => {
    this.setState({token: token})
    this.setState({isLoggedIn:true})
  }

  logout = () => { 
    this.setState({token: ""})
    this.setState({isLoggedIn: false})
    this.setState({user: null})
  }

  setSections = (sections) => {
    this.setState({sections: sections})
  }

  
    setCourses() {
        
        fetch("https://backend.ornatemaritime.net/api/courses")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              courses: result
            });
            console.log(result)
          },
          
          (error) => {
            this.setState({
              courses: []
            })}
          
        )    
    }

 
       
     
  render() {

    const { error, isLoaded, courses, sections, token, isLoggedIn, user } = this.state;

    console.log(error, isLoaded, courses, token, isLoggedIn, user)
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>     
          <Header isLoggedIn={isLoggedIn} user={user} />
          <br/>
          <br/>
          <Jumbotron user={user} />
          <Layout>
            <Switch>
              <Route key="home" exact path="/" component={Home} />
              <Route key="courses"  path="/courses" render={(props) => (
                <Courses {...props} courses={courses} isLoggedIn={isLoggedIn} user={user} token ={token} sections={sections} 
                setSections={this.setSections} />
              )}/>
              <Route key="about"  path="/about" component={About} />
              <Route key="contact"  path="/contact" render={(props) => <Contact {...props}/>}/>
              <Route key="verify"  path="/verify" render={(props) => <Verify {...props} />}/>
              <Route key="login"  path="/login" render={(props) => (<Login {...props} setToken={this.setToken} token={token} setUser={this.setUser}/>)}></Route>
              <Route key="logout"  path="/logout" render={(props) => (<Logout {...props} logout={this.logout} token={token}/>)} />
              <Route key="register"  path="/register" render={(props) => (<Register {...props} setToken={this.setToken} />)} />
              <Route key="Profile"  path="/Profile" render={(props) => (<Profile {...props} user={user} token={token}/>)} />
              <Route key="success"  path="/success" Component={Success} />
              <Route key="error"  path="/error" Component={Error} />
              <Route key="contactfees"  path="/library" render={(props) => <CourseFees {...props}/>}/>
            </Switch>
          </Layout> 
          <Footer/>
        </div>
      )
    }
  }
}