import { Form, Button, Container, InputGroup } from "react-bootstrap"
import { useState, useEffect } from "react"
import { Otp } from "./Otp"


export const EditProfile = (props) => {
    const response = props.response
    
    const token = props.token
    const [dob, setDob] = useState("");    
    const [picture, setPicture] = useState("");  
    const [phone, setPhone]  = useState("");    
    const [streetAddress, setstreetAddress] = useState(""); 
    const [city, setCity] = useState("");
    const [addressState, setAddressState] = useState("");
    const [pin, setPinCode] = useState("");
    const [cdc, setCdc] = useState("");
    const [passport, setPassport] = useState("");
    const [grade, setGrade] = useState("-----");
    const [indos, setIndos] = useState("");
    const [signature, setSignature] =  useState("");
    const [loaded, setLoaded] = useState(false);
    const [selectedPicture, setselectedPicture] = useState(null);
    const [pflag, setpflag] = useState(true)
    const [customerids, setCustomerids] = useState({id_number:'', id_type:'', id_img :null});

       
    const [selectedSignature, setselectedSignature] = useState(null);

    const id_types = ["-----","PANCARD NUMBER","PASSPORT NUMBER","AADHARCARD NUMBER","VOTERCARD","DRIVING LICENCE NUMBER"]

    const checkDob = () => {
        if (props.response.dob) {
            return true
        } else {
            return false
        }
    }

    function onChangeCustomerid(e) {
        const val = e.target.value;
        
        if ([e.target.name] == "id_number"){
            setCustomerids(prevState => {
                return Object.assign({id_number : val, id_type: prevState.id_type, id_img: prevState.id_img});
              });
        }

        if ([e.target.name] == "id_type"){
            setCustomerids(prevState => {
                return Object.assign({id_number : prevState.id_number, id_type: val, id_img: prevState.id_img});
              });
        }

        if ([e.target.name] == "id_img"){
            setCustomerids(prevState => {
                return Object.assign({id_number : prevState.id_number, id_type: prevState.id_type, id_img: e.target.files[0]});
              });
        }
        
      }

    
      function handleChange(e) {
        const file = e.target.files[0];
        const name = e.target.name;
    
        // File size validation
        const maxSizeInBytes = 2 * 1024 * 1024; // 5MB
        if (file && file.size > maxSizeInBytes) {
            alert("File size exceeds the maximum limit of 2MB");
            return;
        }
    
        // File type validation
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
        if (file && !allowedTypes.includes(file.type)) {
            alert("Invalid file type. Only JPEG, PNG, and JPG files are allowed.");
            return;
        }
    
        // Image dimension validation for signature
        if (name === 'signature') {
            const maxWidth = 450;
            const maxHeight = 150;
            if (file) {
                const img = new Image();
                img.onload = function () {
                    if (img.width > maxWidth || img.height > maxHeight) {
                        alert(`Image dimensions should not exceed ${maxWidth}x${maxHeight} pixels.`);
                        return;
                    }
                    
                    // Check the corners for background color
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
    
                    const checkCorners = (imageData) => {
                        const corners = [
                            [0, 0], // top-left
                            [imageData.width - 1, 0], // top-right
                            [0, imageData.height - 1], // bottom-left
                            [imageData.width - 1, imageData.height - 1], // bottom-right
                        ];
    
                        for (let [x, y] of corners) {
                            const index = (y * imageData.width + x) * 4;
                            const red = imageData.data[index];
                            const green = imageData.data[index + 1];
                            const blue = imageData.data[index + 2];
                            const alpha = imageData.data[index + 3];
    
                            // Check if corner pixel is not white and not transparent
                            if (!((red >= 240 && green >= 240 && blue >= 240) || alpha === 0)) {
                                return false;
                            }
                        }
                        return true;
                    };
    
                    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    if (!checkCorners(imageData)) {
                        alert("Signature must have a white or transparent background.");
                        return;
                    }
    
                    // If all validations pass, update the state
                    setselectedSignature(file);
                };
                img.src = URL.createObjectURL(file);
            }
        } else {
            // If the file is not a signature, update the state normally
            if (name === 'picture') {
                setselectedPicture(file);
            }
        }
    }
     // console.log("selectedpic", selectedPicture)
     // console.log("selectedsignature", selectedSignature)
     // console.log("customerid:", customerids)
      
    const setResponse = (response) => {
        if (response) {
            setDob(response.date_of_birth)
            setPhone(response.phone)
            setstreetAddress(response.street_address)
            setCity(response.city)
            setPinCode(response.pin_code)
            setCdc(response.cdc_number)
            setPassport(response.passport_number)
            setGrade(response.grade_number || "-----")
            setIndos(response.indos_number)
            setLoaded(true)
            setPicture(response.picture)
            setAddressState(response.state)
            setSignature(response.signature)
        }
    }


    const handleSubmitFile = (evt) => {            
        evt.preventDefault();
        let formData = new FormData()
        if (selectedPicture) {
            formData.append('picture', selectedPicture)
        }
        if (selectedSignature) {            
            formData.append('signature', selectedSignature)
        }
        if (customerids) {            
            for ( var key in customerids ) {
                formData.append(key, customerids[key]);
            }            
        }
               
       // console.log("formdata:", formData)
        fetch("https://backend.ornatemaritime.net/" + '/api/customer_upload',{
        method: 'POST',    
        headers: {            
            "Authorization": `Token ${token}`,
          },
        body: formData,
        }).then(res => res.json())
        .then(
          (result) => {
              //console.log("submit file result: ",result)            
              alert("Document uploaded successfully")  
              props.cancelUpdateProfile()
              //resetForm()
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            alert("Error occurred" + error)  
          })
          
          
        }
    

     const handleSubmitJson = (evt) => {            
            evt.preventDefault();            
            if (phone !== props.response.phone && pflag || phone === props.response.phone) {
                fetch("https://backend.ornatemaritime.net/" + '/api/customer',{
                // fetch("http://127.0.0.1:8000/" + '/api/customer',{
            method: 'POST',    
            headers: {
                "Content-type": "application/json",                
                "Authorization": `Token ${token}`,
              },
            body: JSON.stringify({
                date_of_birth: dob,
                phone: phone,                
                street_address: streetAddress,         
                city: city,
                pin_code: pin,
                state: addressState,
                passport_number: passport,
                cdc_number : cdc,                             
                grade_number : grade,
                indos_number : indos,
                //customerids : ["id_img" : selectedDocument, "id_number": selectedDocumentNumber, "id_type": selectedIdType],            
            }),
            }).then(res => res.json())
            .then(
              (result) => {
                //console.log(result)            
                  alert("Profile updated successfully")  
                  props.cancelUpdateProfile()
                  //resetForm()
              },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {
                alert("Error occurred" + error)  
              })
            }
            else {
                alert("Phone not verified")
                return
            }              
            }
      
  useEffect(() => {    
    setResponse(response)    
   },[]);

   //console.log("pflag:" ,{pflag})
    if (loaded) {
        return (
            <Container> 
                <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                            <Container fluid>
                            <br/>
                            <h4 className="text-center text-info"><u>Personal Details</u></h4>
                        <Form onSubmit={handleSubmitJson}>                          

                            <Form.Group controlId="formEditProfileDoB">
                                <Form.Label>Date of birth</Form.Label>
                                <Form.Control type="date" readOnly={checkDob} autoFocus value={dob} onChange={e => setDob(e.target.value)}
                               required  />                            
                            </Form.Group>

                            <Form.Group controlId="formEditProfilePhone">
                                <Form.Label className="required">Phone</Form.Label>
                                <InputGroup>
                                
                                <Form.Control type="number" maxLength="10" required placeholder="Enter phone" value={phone} onChange={e => {setPhone(e.target.value); setpflag(false)}} />                    
                    
                                <InputGroup.Append>
                                    <Otp otptype="phone" otpkey={phone} verified={pflag} setVerified={setpflag}/>   
                                </InputGroup.Append>
                                </InputGroup> 

                            </Form.Group>

                            <Form.Group controlId="formEditProfileStreetAddress">
                                <Form.Label>Street Address</Form.Label>
                                <Form.Control type="text"  value={streetAddress} onChange={e => setstreetAddress(e.target.value)}
                                maxLength="100" placeholder="Enter street address" />                            
                            </Form.Group> 

                            <Form.Group controlId="formEditProfileCity">
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text"  value={city} onChange={e => setCity(e.target.value)}
                                maxLength="100" placeholder="Enter city" />                            
                            </Form.Group>                            
                            
                            <Form.Group controlId="formEditProfileState">
                                <Form.Label>State</Form.Label>
                                <Form.Control type="text"  value={addressState} onChange={e => setAddressState(e.target.value)}
                                maxLength="100" placeholder="Enter state" />                            
                            </Form.Group> 

                            <Form.Group controlId="formEditProfilePin">
                                <Form.Label>Pin code</Form.Label>
                                <Form.Control type="text"  value={pin} onChange={e => setPinCode(e.target.value)}
                                maxLength="10" placeholder="Enter Pin Code" pattern="[0-9]*"/>                            
                            </Form.Group> 

                            <Form.Group controlId="formEditProfilePassport">
                                <Form.Label>Passport Number</Form.Label>
                                <Form.Control type="text"  value={passport} onChange={e => setPassport(e.target.value)}
                                maxLength="100" placeholder="Enter passport number" />                            
                            </Form.Group> 

                            <Form.Group controlId="formEditProfileCdc">
                                <Form.Label>CDC Number</Form.Label>
                                <Form.Control type="text"  value={cdc} onChange={e => setCdc(e.target.value)}
                                maxLength="100" placeholder="Enter Cdc number" />                            
                            </Form.Group> 

                            <Form.Group controlId="formEditProfileGrade">
                                <Form.Label>Grade NO./COC NO.</Form.Label>
                                <Form.Control type="text"  value={grade} onChange={e => setGrade(e.target.value)}
                                maxLength="100" placeholder="Enter Grade" />                            
                            </Form.Group> 

                            <Form.Group controlId="formEditProfileIndos">
                                <Form.Label>INDOS Number</Form.Label>
                                <Form.Control type="text"  value={indos} onChange={e => setIndos(e.target.value)}
                                maxLength="100" placeholder="Enter Indos" />                            
                            </Form.Group> 
                            
                            <Button className="w-100" variant="info" type="submit" onClick={handleSubmitJson}>
                                Submit Changes
                            </Button>
                            
                         
                        </Form>
                        </Container>
                        </div>
                        
                        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <Container fluid>
                        <br/>
                        <h4 className="text-center text-info"><u>Documents</u></h4>
                        <Form onSubmit={handleSubmitFile}>        
                            <Form.Group controlId="formEditProfilePic">
                                <Form.Label>Picture </Form.Label>
                                <br/>
                                <img src={"https://backend.ornatemaritime.net/" + picture} height="90px" width="100px" />
                                {/* <img src={"http://127.0.0.1:8000/" + picture} height="90px" width="100px" /> */}
                                <br/>   
                                <br/>                                                
                                <Form.Control type="file"  accept="image/*"  name="picture"  onChange={handleChange}
                                />  
                            </Form.Group>
                            
                            <Form.Group controlId="formEditProfileSig">
                                <Form.Label>Signature </Form.Label>
                                <br/>
                                <img src={"https://backend.ornatemaritime.net/" + signature} height="90px" width="100px" />
                                {/* <img src={"http://127.0.0.1:8000/" + signature} height="90px" width="100px" /> */}
                                <br/>   
                                <br/>                                                
                                <Form.Control  type="file" accept="image/*"  name="signature" onChange={handleChange}
                                />  
                            </Form.Group>

                            <Form.Group controlId="formEditProfileIDtype">
                                <Form.Label>ID TYPE</Form.Label>
                                <Form.Control as="select" value={customerids['id_type']} name="id_type" onChange={onChangeCustomerid}>
                                
                               {id_types.map((id_type) => (
                                 <option value={id_type}>{id_type}</option>                                
                               ))}                               
                               
                                </Form.Control>
                            </Form.Group>
                            
                            <Form.Group controlId="formEditProfileIDNumber">
                                <Form.Label>Document Number</Form.Label>
                                <Form.Control type="text" value={customerids['id_number']} name="id_number" onChange={onChangeCustomerid}
                                />                      
                            </Form.Group>

                            <Form.Group controlId="formEditProfileIDfile">
                                <Form.Label>Document</Form.Label>
                                <Form.Control type="file" accept="image/*"  name="id_img" onChange={onChangeCustomerid}
                                />                      
                            </Form.Group>

                            <Button className="w-100" variant="info" type="submit" onClick={handleSubmitFile}>
                                Submit Changes
                            </Button>
                        </Form>    
                        </Container>
                      
                        <br/>
                        </div>
                       
                       
                </div>
                <br/>
                <br/>
                <Button  variant="secondary" className="w-100" type="submit" onClick={props.cancelUpdateProfile}>
                            Cancel Edit
                </Button>
                           
                <br/>
                <br/>
               
          </Container>      
        )
    } else {
        return "loading"
    }
  
   
}