import React, { useState } from "react";
import PrivacyPolicy from "./PrivacyPolicy"; 

export const Footer = () => {
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

    const togglePrivacyPolicy = () => {
        setShowPrivacyPolicy(!showPrivacyPolicy);
    }

    return (
        <footer id="footer" className="footer text-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <h4 className="text-uppercase mb-4"><u>Contact</u></h4>
                        <p className="lead mb-0, font-size">
                            Call us on : +91-9004091006
                        </p>
                        <p className="lead mb-0">
                            Email us on:
                        </p>
                        <p className="lead mb-0">
                            admin@ornatemaritime.net
                        </p>
                        <p className="lead mb-0">
                            shreya@ornatemaritime.net
                        </p>
                    </div>
                    <div className="col-lg-2 mb-5 mb-lg-0">
                    </div>
                    <div className="col-lg-6">
                        <h4 className="text-uppercase mb-4"><u>Training Centres</u></h4>
                        <div className="row">
                            <div className="col-lg-6">
                                <p>
                                    B- 602, The Great Eastern Summit, Plot-66,
                                    Sector-15, CBD Belapur,
                                    Navi Mumbai - 400614, Maharashtra, India.
                                </p>
                            </div>
                            <div className="col-lg-6">
                                <p>
                                    Plot No-A/462, A G Colony,
                                    Near A G Colony Park, 
                                    Patna - 800025, 
                                    Bihar, India.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <button onClick={togglePrivacyPolicy} className="btn btn-link">
                    {showPrivacyPolicy ? 'Hide Privacy & Policies' : 'Show Privacy Policies'}
                </button>
                {showPrivacyPolicy && <PrivacyPolicy />}
            </div>
        </footer>
    );
}
