import { useEffect } from 'react';

export const Capture = (props) => {

useEffect(() => {  
   console.log(" webcam capture props updated")
})

if (props.captureImage && props.image) {  
  props.setcaptureImage(false)
  props.addimage(props.image)
 return ""
}
return ""
}