import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button, InputGroup, FormControl, Icon } from 'react-bootstrap';
import { Otp } from './Otp';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 

export const RegisterForm = (props) => {
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");       
    const [password1, setPassword1] = useState("");     
    const [password2, setPassword2] = useState("");   
    const [passwordErrors, setPasswordErrors] = useState([]); 
    const [showPasswordErrors, setShowPasswordErrors] = useState(false); // New state for error visibility
    const [phone, setPhone] = useState("");   
    const [title, setTitle] = useState("");  
    const [err, setErr] = useState(null);
    const history = useHistory();
    const [eflag, seteflag] = useState(false);
    const [pflag, setpflag] = useState(false);

    // State for password visibility
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    
    const titles = ["","Mr.","Mrs.","Ms."];

    const validatePassword = (password) => {
        const errors = [];
        if (password.length < 8) {
            errors.push("Password must be at least 8 characters long");
        }
        if (!/\d/.test(password)) {
            errors.push("Password must contain at least one number");
        }
        if (!/[a-z]/.test(password)) {
            errors.push("Password must contain at least one lowercase letter");
        }
        if (!/[A-Z]/.test(password)) {
            errors.push("Password must contain at least one uppercase letter");
        }
        if (!/[^a-zA-Z0-9]/.test(password)) {
            errors.push("Password must contain at least one special character.");
        }
        return errors;
    };

    useEffect(() => {
        if (showPasswordErrors) {
            setPasswordErrors(validatePassword(password1));
        }
    }, [password1, showPasswordErrors]);

    const handleSubmit = (evt) => {        
        evt.preventDefault();

        // Trigger password validation on submit
        setShowPasswordErrors(true);
        setPasswordErrors(validatePassword(password1));

        if (eflag && pflag) {
            if (password1 !== password2) {
                alert("Passwords do not match");
                return;
            }
            if (passwordErrors.length > 0) {
                alert(passwordErrors.join("\n"));
                return;
            }

            // Assuming the fetch operation here
            fetch("https://backend.ornatemaritime.net/api/register", {
                method: 'POST',
                body: JSON.stringify({
                    first_name: first_name,
                    last_name: last_name,
                    email: email,
                    password: password1,
                    phone: phone,
                    title: title
                }),
                headers: {
                    "Content-type": "application/json"
                }
            }).then(response => response.json())
            .then(myJson => {
                if ("token" in myJson) {                
                    props.setToken(myJson["token"]);
                    alert("Registration successful");
                    history.push("/");                         
                } else {
                    var error = myJson["error"];
                    alert("Account created Successfully...User registration is pending admin approval. You will be notified once approved.");
                }
            }).catch(err => {
                setErr(err);
                alert(err);
            }); 
        } else {
            alert("Email and phone verification are required.");
        }       
    };      

    // Toggle password visibility
    const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
    const toggleConfirmPasswordVisibility = () => setConfirmPasswordVisible(!confirmPasswordVisible);

    return (
        <div className="fluid-container">
            <Form onSubmit={handleSubmit}>

                <Form.Group controlId="formBasicRegisterTitle">
                    <Form.Label className="required">Title</Form.Label>
                    <Form.Control as="select" value={title} name="title" onChange={e => setTitle(e.target.value)}>
                        {titles.map((title, index) => (
                            <option value={title} key={index}>{title}</option>                                
                        ))}                               
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="formBasicRegisterName">
                    <Form.Label className="required">First Name</Form.Label>
                    <Form.Control type="text" value={first_name} onChange={e => setFirstName(e.target.value)}
                    maxLength="50" required autoFocus placeholder="Enter first name" />                            
                </Form.Group>

                <Form.Group controlId="formBasicRegisterLastName">
                    <Form.Label className="required">Last Name</Form.Label>
                    <Form.Control type="text" value={last_name} onChange={e => setLastName(e.target.value)}
                    maxLength="50" required placeholder="Enter last name" />                            
                </Form.Group>

                <Form.Group controlId="formBasicRegisterEmail">
                    <Form.Label className="required">Email</Form.Label>
                    <InputGroup>
                        <Form.Control type="email" readOnly={eflag} value={email} onChange={e => setEmail(e.target.value)}
                        maxLength="100" required placeholder="Enter email" />
                        <InputGroup.Append>
                            <Otp otptype="email" otpkey={email} setVerified={seteflag}/>                        
                        </InputGroup.Append>
                    </InputGroup>                           
                </Form.Group>

                <Form.Group controlId="formBasicRegisterPhone">
                    <Form.Label className="required">Phone</Form.Label>
                    <InputGroup>
                        <Form.Control type="number" readOnly={pflag} maxLength="10" required placeholder="Enter phone" value={phone} onChange={e => setPhone(e.target.value)} />                    
                        <InputGroup.Append>
                            <Otp otptype="phone" otpkey={phone} setVerified={setpflag}/>   
                        </InputGroup.Append>
                    </InputGroup> 
                </Form.Group>

                <Form.Group controlId="formBasicRegisterPassword">
                    <Form.Label className="required">Password</Form.Label>
                    <InputGroup>
                        <FormControl
                            type={passwordVisible ? "text" : "password"}
                            maxLength="100"
                            value={password1}
                            onChange={(e) => setPassword1(e.target.value)}
                            required
                            placeholder="Enter password"
                        />
                        <InputGroup.Append>
                            <Button variant="outline-secondary" onClick={togglePasswordVisibility}>
                                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                    {showPasswordErrors && passwordErrors.length > 0 && (
                        <div className="text-danger mt-2">
                            {passwordErrors.map((error, index) => (
                                <span key={index} className="mr-2">
                                    {error}
                                    {index !== passwordErrors.length - 1 && ","}
                                </span>
                            ))}
                        </div>
                    )}
                </Form.Group>

                <Form.Group controlId="formBasicRegisterConfirmPassword">
                    <Form.Label className="required">Re-enter Password</Form.Label>
                    <InputGroup>
                        <FormControl
                            type={confirmPasswordVisible ? "text" : "password"}
                            maxLength="100"
                            value={password2}
                            onChange={e => setPassword2(e.target.value)}
                            required
                            placeholder="Re-enter password"
                        />
                        <InputGroup.Append>
                            <Button variant="outline-secondary" onClick={toggleConfirmPasswordVisibility}>
                                {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>

                <div className="row">
                    <div className="col-6">
                        <Button className="w-100" variant="primary" type="submit">
                            Submit
                        </Button>
                    </div>
                    <div className="col-6">
                        <a className="w-100 btn btn-secondary" href="/Login"  type="submit">
                            Cancel
                        </a>                  
                    </div>                  
                </div>
            </Form>
            <br/>
            <br/>
        </div>
    );
};
