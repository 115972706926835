
import { useState, useEffect } from 'react';  
import { Modal, Button } from 'react-bootstrap';
import { WebcamCapture } from './WebcamCapture';


export const Test = (props) => {

    const [show, setShow] = useState(false);

    const [currentQuestion, setCurrentQuestion] = useState(0);

    const [questions, setQuestions] = useState([]);

    const [seconds, setSeconds] = useState(-1);

    const [images, setimages] = useState([])

    let test = props.test
    let showTest = props.showTest
    
    const addimage = (image) => {
        setimages(images => images.concat(image))
    }

    useEffect(() => {
        if (seconds > 0 && showTest) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
          } 
        
        if (seconds === 60) {
            alert("Last 60 seconds remaining exam will be submitted in 60 seconds")
        }
        if (seconds == 0) { 
            alert("Time up! Exam submitted")
            submitExamTimeOut()
          }
        
        if (questions.length == 0 && showTest) {
            
            setSeconds(test.duration_minutes * 60)
            
            //fetch questions
        fetch("https://backend.ornatemaritime.net/" + "/api/question?exam=" + test.id,{
            method: 'GET',          
            headers: {            
                "Authorization": `Token ${props.token}`
            }})
        .then((response) => {
            let res = response.json()
            console.log(response)
            if (response.status === 200) {
                return res   
            } else if (response.status === 404) {
                alert("Not found")          
                return res      
            } else if (response.status === 401) {
                alert("Unauthorized")
                return res          
            }
        }
        )
        .then(
            (result) => {
                setQuestions(result)
                setShow(true)                    
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
            console.log(error)
            }
        )
        
        }
        
    })
    
    
    
    const submitAnswer = (answer) => {
        questions[currentQuestion].answer = answer
        //const nextQuestion = currentQuestion + 1;
        //    if (nextQuestion < questions.length) {
        //        setCurrentQuestion(nextQuestion)  
        //    }  else {
        //        submitExam()
        //    }            
        }        
  
    const nextQuestion = () => {
        
        const nextQuestion = currentQuestion + 1;
        if (nextQuestion  < questions.length) {
            setCurrentQuestion(nextQuestion);
        } else {
            alert("No more questions")   
        }        
        
    }
    
    const previousQuestion = () => {
        const previousQuestion = currentQuestion - 1;
        if (previousQuestion >= 0) {
            setCurrentQuestion(previousQuestion);
        } else {
            alert("No previous question")
        }        
        
    }
    
         
    let handleClose = () => {
        if(window.confirm("Close exam without submitting?")) {
            resetState()
            props.closeTest()
        }            
    }

    const resetState = () => {
        setQuestions([])
        setCurrentQuestion(0)
        setShow(false)
        setSeconds(-1)
        setimages([])
    }
    

    let submitExam = () => {
        if(window.confirm("Submit exam?")) {
            test.submission = questions       
            test.seconds = test.duration_minutes*60 - seconds     
            resetState()       
            props.submitTest(test,images)            
            props.closeTest()
        }      
    }

    let submitExamTimeOut = () => {
    
        test.submission = questions
        test.seconds = test.duration_minutes*60
        resetState()
        props.submitTest(test,images)
        props.closeTest()
  
    }
    
    //console.log("test test:" + test)
    //console.log("test questions:" + questions)
    //console.log("seconds:" + seconds)
    console.log("images", images)

    const isActive = (choice) => {
        if (questions[currentQuestion].answer == choice) {
            return true
        } else {
            return false
        }
    }
    
    const timeleft = () => {
        let min = Math.floor(seconds/60)
        let sec = seconds%60

        if (seconds > 60) {
            if (min == 1) {
                return (min + " minute " + sec + " seconds")
            } else  {
                return (min + " minutes " + sec + " seconds")
            }
        } else {
            return (seconds + " seconds")
        }
    }

    

    if (showTest && questions.length > 0) {      
            
            return (
                <div> 
                    <WebcamCapture addimage={addimage} seconds={seconds} show={show} />
                    <Modal show={show} fullscreen={true}  onHide={handleClose} animation
                    size="xl"                    
                    aria-labelledby="contained-modal-title-vcenter"
                    centered                
                    scrollable
                    >  
                        <Modal.Header closeButton>  
                            <Modal.Title id="exam">  
                               Welcome to {test.examid}
                               <h6>Time left: {timeleft()}</h6>
                            </Modal.Title>                            
                        </Modal.Header> 

                        <Modal.Body>
                        <div className='question-section'>
                            <div className='question-count'>
                                Question <b>{currentQuestion + 1}/{questions.length}</b>
                            </div>
                            <br/>
                            <div className='question-text'><b>{questions[currentQuestion].description}</b></div>
                            <br/>
                        </div> 
                        <div className='answer-section d-grid gap-2'>
                                <Button active={isActive(1)} variant="outline-primary" size="lg" onClick={() => submitAnswer(1)}>{questions[currentQuestion].answer_choice_1}</Button>
                                <Button active={isActive(2)} variant="outline-primary" size="lg" onClick={() => submitAnswer(2)}>{questions[currentQuestion].answer_choice_2}</Button>
                                <Button active={isActive(3)} variant="outline-primary" size="lg" onClick={() => submitAnswer(3)}>{questions[currentQuestion].answer_choice_3}</Button>
                                <Button active={isActive(4)} variant="outline-primary" size="lg" onClick={() => submitAnswer(4)}>{questions[currentQuestion].answer_choice_4}</Button>
                         </div>                                                      
                          <br/>  
                 
                    </Modal.Body>  
                    <div className="container row">
                        <div className="col-6"> 
                            <button  className = "btn btn-info btn-block" onClick={() => previousQuestion()}>Previous</button> 
                        </div>
                        <div className="col-6">
                            <button className = "btn btn-secondary btn-block"  onClick={() => nextQuestion()}>Next</button> 
                        </div>                        
                    </div>

                    <br/>
                    
                    <div className="container row">
                        <div className="col-6"> 
                            <button  className = "btn btn-primary btn-block" onClick={submitExam}>Submit</button> 
                        </div>
                        <div className="col-6">
                            <button className = "btn btn-secondary btn-block" onClick={handleClose}>Cancel</button> 
                        </div>                        
                    </div>
                  
                   <br/>
                    </Modal >  
                </div >  
            )    
      
        }
   else {
       return ""
   }    
    }