import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";


export const Header = (props) => {
    const isLoggedIn = props.isLoggedIn;
    const user = props.user;
    if (isLoggedIn) {
        return (
        
            <Navbar collapseOnSelect expand="lg" className="navbar navbar-expand-lg text-uppercase fixed-top" id="mainNav">
                <Container>                    
                    {/* <a class="navbar-brand" href="/">ORNATE</a> */}
                    {/* <img className="navbar-brand" src="../assets/img/../assets/img/ship.png.png"/> */}
                    <Nav.Link as={Link} className="" to="/" href="/" exact><img class=""  src="assets/img/ornatelogonew.png" width={"90px"}/></Nav.Link>
                    <Navbar.Toggle className="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button"  aria-controls="navbarResponsive">      Menu
                        <i className="fas fa-bars"></i></Navbar.Toggle>
                    <Navbar.Collapse id="navbarResponsive">
                        <Nav className="ms-auto">
                        <li className="nav-item mx-0 "><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded" to="/" href="/" exact>HOME</Nav.Link></li>
                            {/* <li className="nav-item mx-0 mx-lg-1"><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded" to="#" href="#Overview" exact>OVERVIEW</Nav.Link></li> */}
                            <li className="nav-item mx-0 "><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded" to="/courses"  href="/courses">COURSES  </Nav.Link></li>
                            <li className="nav-item mx-0 "><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded" to="/library" href="/library">LIBRARY</Nav.Link></li>
                            <li className="nav-item mx-0 "><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded" to="/contact" href="/contact">CONTACT</Nav.Link></li>
                            {/* <li className="nav-item mx-0 "><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded" to="#team_work" href="">TEAM WORK</Nav.Link></li> */}
                            <li className="nav-item mx-0 "><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded" to="/verify" href="/verify">VERIFY CERT</Nav.Link></li>
                            {/* <li className="nav-item mx-0 "><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded" to="/login" href="/login">Login</Nav.Link></li> */}
                            <li className="nav-item mx-0 "><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded" to="/logout"  href="/logout">Logout</Nav.Link></li>
                            <li className="ms-2"><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded btn btn-primary text-white" to="/profile"  href="/profile">Dashboard</Nav.Link></li>
                        </Nav>
                    </Navbar.Collapse>                
                </Container>
                <Nav.Link as={Link} className="" to="/" href="/" exact><img class=""  src="assets/img/marlins1.png" width={"200px"} height={"auto"}/></Nav.Link>
            </Navbar>
        )
    } else {
        return (

            <Navbar collapseOnSelect expand="lg" className="navbar navbar-expand-lg text-uppercase fixed-top" id="mainNav">
                <Container>                    
                    {/* <a class="navbar-brand" href="/">ORNATE</a> */}
                    <Nav.Link as={Link} className="" to="/" href="/" exact><img class=""  src="assets/img/ornatelogonew.png" width={"90px"}/></Nav.Link>
                    
                    <Navbar.Toggle className="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button"  aria-controls="navbarResponsive">      Menu
                        <i className="fas fa-bars"></i></Navbar.Toggle>
                    <Navbar.Collapse id="navbarResponsive">
                        <Nav className="ms-auto">
                            <li className="nav-item mx-0 "><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded" to="/" href="/" exact>HOME</Nav.Link></li>
                            {/* <li className="nav-item mx-0 "><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded" to="#" href="#Overview" exact>OVERVIEW</Nav.Link></li> */}
                            <li className="nav-item mx-0 "><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded" to="/courses"  href="/courses">COURSES  </Nav.Link></li>
                            <li className="nav-item mx-0 "><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded" to="/library" href="/library">LIBRARY</Nav.Link></li>
                            <li className="nav-item mx-0 "><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded" to="/contact" href="/contact">CONTACT</Nav.Link></li>
                            {/* <li className="nav-item mx-0 "><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded" to="#team_work" href="">TEAM WORK</Nav.Link></li> */}
                            <li className="nav-item mx-0 "><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded" to="/verify" href="/verify">VERIFY CERT</Nav.Link></li>
                            <li className="ms-2"><Nav.Link as={Link} className="nav-link py-1 px-0 px-lg-3 rounded btn btn-primary text-white" to="/login" href="/login">Login</Nav.Link></li>
                        </Nav>
                    </Navbar.Collapse>                
                </Container>
                <Nav.Link as={Link} className="" to="/" href="/" exact><img class=""  src="assets/img/marlins1.png" width={"200px"} height={"auto"}/></Nav.Link>
            </Navbar>
        )
    }
}
