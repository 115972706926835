import {Button} from 'react-bootstrap'

export const EnrollmentAction = (props) => {
    const enrollment = props.enrollment
 //   if (enrollment.payment_status !== "Success" && enrollment.status === "ENROLLED") {
    if (enrollment.payment_status !== "Success" && enrollment.status !== "CANCELLED" && enrollment.status !== "PASSED" && enrollment.status !== "COMPLETED") {  
        return (
            <Button className="w-100 btn btn-secondary" type="submit" 
            onClick={(e) => {if(window.confirm("Cancel " + enrollment.course_description + " enrollment?")) {props.cancelEnrollment(e,enrollment)}}}>
                            Submit
            </Button>
        )
    } else {
        return (
            <Button className="w-100 btn btn-secondary" type="submit" 
            disabled >
                 Submit
            </Button>
        )        
    }
}