import Webcam from "react-webcam";
import { useEffect, useRef, useState, useCallback } from 'react';
import { Capture } from "./Capture";
export const WebcamCapture = (props) => 
{  

const [image,setImage]=useState('')
const [captureImage,setcaptureImage]=useState(false)
const {exam, token, seconds, show} = props
const webcamRef = useRef(null);

//console.log(exam, token, seconds, show, captureImage, image)

const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc)
  }

useEffect(() => {  
  if (show && (seconds%60 === 0) && captureImage === false) {    
    capture()
    if (image)  {
     // alert("use effect webcam")
      setcaptureImage(true)    
    }    
  }
})

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

return (
      <div>        
        <Webcam
          audio={false}
          height={720}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={1280}
          videoConstraints={videoConstraints}
        />
        <Capture captureImage={captureImage} setcaptureImage={setcaptureImage} image={image} addimage={props.addimage}/>
      </div>
  );
};