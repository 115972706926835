import React, { useState, useRef, useEffect } from 'react'
import { Form, Button, InputGroup } from 'react-bootstrap'
import { Otp } from './Otp';

export const Contactform = () => {

    const [name, setName] = useState("");    
    const [email, setEmail] = useState("");       
    const [phone, setPhone] = useState(""); 
    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState("");
    const [eflag, seteflag] = useState(false)
    const [pflag, setpflag] = useState(false)
    

    const resetForm = () => {
        setName("")
        setEmail("")
        setPhone("")
        setSubject("")
        setMessage("")
        seteflag(false)
        setpflag(false)         
    }

    const handleSubmit = (evt) => {

        evt.preventDefault();   
        if (eflag && pflag) {
            fetch("https://backend.ornatemaritime.net/" + '/api/contact',{
                // fetch("http://127.0.0.1:8000/" + '/api/contact',{
            method: 'POST',
            body: JSON.stringify({
                name: name,
                phone: phone,
                email: email,         
                message: message,
                subject: subject,
            }),
            headers: {
                "Content-type": "application/json"
            }
            }).then(res => res.json())
            .then(
            (result) => {
                //console.log(result)            
                alert("Query submitted successfully. Your ticket# is" + result.id)  
                resetForm()
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                alert("Error occurred" + error)  
            })  
        } else {
            alert("Email and/or Phone not verified")
            return
        }
    }
        
   
    return(
    <div className="container">
        <h2 >Have Any questions</h2>
         <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicContactName">
                <Form.Label className="required">Name</Form.Label>
                <Form.Control type="text" maxLength="50" autoFocus value={name} onChange={e => setName(e.target.value)} required placeholder="Enter name" />                
            </Form.Group>

            <Form.Group controlId="formBasicContactEmail">
                    <Form.Label className="required">Email</Form.Label>
                    <InputGroup>
                    <Form.Control type="email" readOnly={eflag} value={email} onChange={e => setEmail(e.target.value)}
                    maxLength="100" required placeholder="Enter email" />
                     <InputGroup.Append>
                        <Otp otptype="email" otpkey={email} setVerified={seteflag}/>                        
                    </InputGroup.Append>
                    </InputGroup>                           
                </Form.Group>


            <Form.Group controlId="formBasicContactPhone">
                    <Form.Label className="required">Phone</Form.Label>
                    <InputGroup>
                    
                    <Form.Control type="number" readOnly={pflag} maxLength="10" required placeholder="Enter phone" value={phone} onChange={e => setPhone(e.target.value)} />                    
         
                     <InputGroup.Append>
                        <Otp otptype="phone" otpkey={phone} setVerified={setpflag}/>   
                    </InputGroup.Append>
                    </InputGroup> 

                </Form.Group>

            <Form.Group controlId="formBasicContactSubject">
                    <Form.Label className="required">Subject</Form.Label>
                    <Form.Control type="text" value={subject} onChange={e => setSubject(e.target.value)}
                    maxLength="100" required placeholder="Enter subject" />                            
                </Form.Group>
            
            <Form.Group controlId="formBasicContactMessage">
            <Form.Label className="required">Message</Form.Label>
                <Form.Control type="textbox" maxLength="500" required size="lg" value={message} onChange={e => setMessage(e.target.value)} />                
            </Form.Group>

            <Button variant="primary w-100" type="submit">
                Submit
            </Button>
        </Form>
    </div>   
    )
}