import React, { useState } from "react";
import {Form, Button} from "react-bootstrap";
import { useHistory } from "react-router-dom";

export const LoginForm = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");    
    const [err, setErr] = useState(null);
    
    const history = useHistory();

    const handleSubmit = (evt) => {
        evt.preventDefault();
        let token = props.token;
    
        if (token !== "") {
            alert("Already logged in");
        } else {
            fetch("https://backend.ornatemaritime.net/api/login",{
            // fetch("http://127.0.0.1:8000/api/login", {
                method: 'POST',
                body: JSON.stringify({
                    username: email,
                    password: password,
                }),
                headers: {
                    "Content-type": "application/json"
                }
            }).then(function (response) {
                console.log(response);
                return response.json();
            }).then(function (myJson) {
                if ("token" in myJson) {
                    const user = myJson["user"];
                    if (user && user.pending_approval) {
                        alert("Pending Admin Approval");
                    } else {
                        props.setToken(myJson["token"]);
                        props.setUser(myJson["user"]);
                        alert("Login successful");
                        setEmail("");
                        setPassword("");
                        history.push("/courses");
                    }
                } else if ("message" in myJson) {
                    // Handle specific messages from the backend
                    alert(myJson["message"]);
                    setEmail("");
                    setPassword("");
                } else if ("error" in myJson) {
                    // Handle specific errors from the backend
                    if (myJson["error"] === "pending_approval") {
                        alert("Pending Admin Approval");
                    } else {
                        alert("Incorrect Email or Password");
                    }
                    setEmail("");
                    setPassword("");
                } else {
                    // Handle any other unexpected cases
                    alert("An unexpected error occurred");
                    setEmail("");
                    setPassword("");
                }
            }, err => {
                setErr(err);
                alert(err);
            });
        }
    };

    return(
        <div className="fluid-container"  id="loginForm">
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label className="required">Email</Form.Label>
                    <Form.Control type="text" autoFocus value={email} onChange={e => setEmail(e.target.value)}
                    maxLength="50" required placeholder="Enter email" />                            
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label className="required">Password</Form.Label>
                    <Form.Control type="password" maxLength="100" value={password} 
                    onChange={e => setPassword(e.target.value)} required placeholder="Enter password" />                
                </Form.Group>
                <Button className="w-100" variant="info" type="submit">
                    Submit
                </Button>
            </Form>
            <br/>

            <div className="row">
                    <div className="col-6">                        
                            <a href="/Register">
                                <Button className="w-100" variant="secondary" type="submit">Signup</Button>
                            </a>                      
                    </div>     
                    <div className="col-6"> 
                            <a href={"https://backend.ornatemaritime.net/" +"/password_reset"}> 
                            {/* <a href={"http://127.0.0.1:8000/" +"/password_reset"}> */}
                                <Button className="w-100" variant="secondary">Reset Password</Button>
                            </a>
                    </div>                  
                </div>	
                <br/>
                <br/>
        </div>
      
    )
}
