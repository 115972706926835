import {Button} from 'react-bootstrap'
import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router";

export const Certificate = (props) => {
    const history = useHistory();
    const [dob, setDob] = useState(null);    
    const [picture, setPicture] = useState(null);  
    const [phone, setPhone]  = useState("");    
    const [streetAddress, setstreetAddress] = useState(""); 
    const [city, setCity] = useState("");
    const [addressState, setAddressState] = useState("");
    const [pin, setPinCode] = useState("");
    const [cdc, setCdc] = useState("");
    const [passport, setPassport] = useState("");
    const [grade, setGrade] = useState("");
    const [indos, setIndos] = useState("");
    const [signature, setSignature] =  useState(null);
    const [documents, setDocuments] = useState(null);
    const [verified, setVerified] = useState(false);
    const [response, setResponse] = useState(null);
    const [editProfile, setEditProfile] = useState(false);

    const enrollment = props.enrollment
    const token = props.token
    let certificate_link = "https://backend.ornatemaritime.net/" + "/certificate?enrollment=" + enrollment.id
    // let certificate_link = "http://127.0.0.1:8000/" + "/certificate?enrollment=" + enrollment.id
    
    
    useEffect(() => {    
        getProfile()    
       },[]);

    let getCertificate = (e) => {
        e.preventDefault()
        if (picture === null) {
            if(window.confirm("Profile picture not updated, update profile picture?")) {                
               window.open("https://backend.ornatemaritime.net/profile","_self")
            //    window.open("http://127.0.0.1:8000/profile","_self")
               history.push('/profile')
            }               
            return       
        }
        /*
        if (signature === null) {            
                if(window.confirm("Signature not updated, update signature?")) {
                    window.open("https://backend.ornatemaritime.net/profile","_self")
                    history.push('/profile')
                }               
            return
        }
        */
        if (dob === null) {            
                if(window.confirm("Date of birth not updated, update date of birth?")) {
                    window.open("https://backend.ornatemaritime.net/profile","_self")
                    // window.open("http://127.0.0.1:8000/profile","_self")
                    history.push('/profile')
                }               
            return
        }       
        alert("Certificated generated in a new window")
        window.open(certificate_link, "_blank")
    }

    const getProfile = () => {
        fetch("https://backend.ornatemaritime.net/" + "/api/customer",{
        // fetch("http://127.0.0.1:8000/" + "/api/customer",{
          method: 'GET',          
          headers: {            
              "Authorization": `Token ${token}` 
          }})
        .then(res => res.json())
        .then(
          (result) => {
            
            //console.log(result)
            setResponse(result)
            setPhone(result.phone)
            setDob(result.date_of_birth)
            setPicture(result.picture)
            setstreetAddress(result.street_address)
            setCity(result.city)
            setCdc(result.cdc_number)
            setPassport(result.passport_number)
            setGrade(result.grade_number)
            setIndos(result.indos_number)
            setSignature(result.signature)
            setDocuments(result.documents)            
            setPinCode(result.pin_code)
            setAddressState(result.state)
            setDocuments(result.customerids)
            
            if (result.verified === true) {                
                setVerified(true)
            } else {
                setVerified(false)
            }
            
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
           // console.log(error)
          }
        )
      }

    
    return(
        <Button className="w-75 btn-primary btn-sm" type="submit" onClick={(e) => getCertificate(e)}>
        Certificate
        </Button>
        )
}