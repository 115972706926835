import React, { useState, useEffect } from 'react';
import { Jumbotron as Jumbo, Container, Carousel } from 'react-bootstrap';
import styled from 'styled-components';
import shipImage1 from '../assets/firstpic.png';
import shipImage2 from '../assets/cargoes.png';
import shipImage3 from '../assets/final.png';
import shipImage4 from '../assets/work.png';

const Styles = styled.div`
  .jumbo {
    // background: url(${shipImage1}) no-repeat fixed bottom; 
    background-size: cover;
    color: #efefef;
    height: 350px;
    position: relative;
    z-index: -2;
  }
  .overlay {
    background-color: #000;
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
`;

export const Jumbotron = (props) => {
  const [index, setIndex] = useState(0);
  const user = props.user;

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Styles>
      <Jumbo fluid className="jumbo" style={{ backgroundImage: `url(${[shipImage1, shipImage2, shipImage3, shipImage4][index]})` }}>
        <div className="overlay"></div>
        <Container>
  <br />
  <br />
  <br />
  {user ? (
    <>
      <h4>Dear {user.first_name},</h4>
      <h1>Welcome to Ornate Maritime Pvt Ltd</h1>
      <p>Marines preferred learning and training management platform</p>
    </>
  ) : (
    <>
      <h1>Welcome to Ornate Maritime Pvt Ltd</h1>
      <p>Marines preferred learning and training management platform</p>
    </>
  )}
</Container>
      
      {/* <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <img className="d-block w-1000" src={shipImage1} alt="First slide" />
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-1000" src={shipImage2} alt="Second slide" />
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-1000" src={shipImage3} alt="Third slide" />
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-1000" src={shipImage4} alt="Fourth slide" />
        </Carousel.Item>
      </Carousel> */}
      </Jumbo>
    </Styles>
  );
};




















  


// FROM BELOW CODE WILL FETCH THE IMAGES THAT WILL BE CHANGED AUTOMATICALLY WHEN THERE IS FESTIVAL.


// import React, { Component, useState, useEffect } from 'react';
// import React from 'react'
// import { Jumbotron as Jumbo, Container } from 'react-bootstrap'
// import styled from 'styled-components'
// import shipImage from '../assets/cargoImage.jpg'
// import Ornate_transparent from '../assets/ornatelogonew.png'


// const Styles = styled.div`
//   .jumbo {
//     background: url(${shipImage}) no-repeat fixed bottom;
//     background-size: cover;
//     color: #efefef;
//     height: 500px;
//     position: relative;
    
//     z-index: -2;
//   }
//   .overlay {
//     background-color: #000;
//     opacity: 0.2;
//     position: absolute;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     z-index: -1;
//   }
// `;


// export const Jumbotron = (props) => {
//   const [imageURL, setImageURL] = useState('');
//   useEffect(() => {
//     // Fetch the current image URL from the backend
//     fetch('http://127.0.0.1:8000/api/current_image_url/')
//       .then((response) => response.json())
//       .then((data) => {
//         // console.log(data);
//         setImageURL(data.image_url);
//       });
//     }, []);

//   const user = props.user
//   if (user) {
//     return (
//       <Styles>
//       <Jumbo fluid className="jumbo">
//         <div className="overlay"></div>
//         <Container>
//           <br/>
//           {/* <img className="img-responsive" src={Ornate_transparent} alt={"logo"} width="250px"/> */}
//           <img className="img-responsive" src={Ornate_transparent} alt={"logo"} width="200px" style={{ marginLeft: "-90px" }} />
//           <br/>
//           <br/>
//           <h4>Dear {user.first_name},</h4>
//           <h1>Welcome to Ornate Maritime Pvt Ltd</h1>
//           <p>Marins preferred learning and training management platform</p>
//         </Container>
//       </Jumbo>
//     </Styles>
//     )  
//   } else {
//     return (
//       <Styles>
//       <Jumbo fluid className="jumbo">
//         <div className="overlay"></div>
//         <Container>
//           <br/>
//           {/* <img className="img-responsive" src={Ornate_transparent} alt={"logo"} width="250px"/> */}
//           <img className="img-responsive" src={Ornate_transparent} alt={"logo"} width="200px" style={{ marginLeft: "-90px" }} />
//           {/* <img src={imageURL.image_url} alt="Special Occasion" /> */}
//           <img class="img-fluid" src={imageURL} alt="Image" />
//           {/* console.log('Image URL:', imageURL); */}
//           {/* <img src={{imageURL}} alt="Special Occasion" /> */}
//           <br/>
//           <h1>Welcome to Ornate Maritime Pvt Ltd</h1>
//           <p>Marines preferred learning and training management platform</p>
//         </Container>
//       </Jumbo>
//     </Styles>
//     )
//   }
//   }
  

// Function to check holiday status
// async function checkHoliday() {
//   try {
//     const response = await fetch('/api/check-holiday');
//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }
//     const data = await response.json();
//     return data.is_holiday;
//   } catch (error) {
//     console.error('Error checking holiday:', error);
//     return false; // Default to non-holiday if there's an error
//   }
// }

// class JumbotronComponent extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isHoliday: false,
//     };
//   }

//   componentDidMount() {
//     this.fetchHolidayData();
//   }

//   async fetchHolidayData() {
//     try {
//       const holiday = await checkHoliday();
//       console.log('Holiday status:', holiday); // Add this line to log the holiday status
//       this.setState({ isHoliday: holiday });
//       if (holiday) {
//         setTimeout(() => {
//           this.setState({ isHoliday: false });
//         }, 24 * 60 * 60 * 1000); // Reset after 24 hours
//       }
//     } catch (error) {
//       console.error('Error fetching holiday data:', error);
//     }
//   }

//   render() {
//     const { isHoliday } = this.state;
//     console.log('Is Holiday:', isHoliday); // Add this line to log the final holiday status
//     const backgroundImage = isHoliday
//       ? `url(${require('../assets/deepawali.jpg')}) no-repeat fixed bottom`
//       : `url(${shipImage}) no-repeat fixed bottom`;

//     return (
//       <Styles>
//         <div className="jumbo" style={{ background: backgroundImage }}>
//           <h1>Your Jumbotron content</h1>
//         </div>
//         <div className="overlay"></div>
//       </Styles>
//     );
//   }
// }

// class JumbotronComponent extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isHoliday: false,
//     };
//   }

//   componentDidMount() {
//     this.fetchHolidayData();
//   }

//   async fetchHolidayData() {
//     try {
//       const holiday = await checkHoliday();
//       this.setState({ isHoliday: holiday });
//       if (holiday) {
//         setTimeout(() => {
//           this.setState({ isHoliday: false });
//         }, 24 * 60 * 60 * 1000); // Reset after 24 hours
//       }
//     } catch (error) {
//       console.error('Error fetching holiday data:', error);
//     }
//   }

//   render() {
//     const { isHoliday } = this.state;
//     const backgroundImage = isHoliday
//       ? `url(${require("../assets/deepawali.jpg")}) no-repeat fixed bottom`
//       : `url(${shipImage}) no-repeat fixed bottom`;

//     return (
//       <Styles>
//         <div className="jumbo" style={{ background: backgroundImage }}>
//           <h1>Your Jumbotron content</h1>
//         </div>
//         <div className="overlay"></div>
//       </Styles>
//     );
//   }
// }