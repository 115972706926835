import {LoginForm} from './components/LoginForm';


export const Login = (props) => {
    return(
        <div className="container">           
            <br/>
            <h2 className="text-info">Please sign in to access your account</h2>   
            <br/>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xm-12">
                    <LoginForm setToken={props.setToken} setUser={props.setUser} token={props.token}/>
                </div>
            </div>
        </div>
    )
}