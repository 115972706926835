import React from 'react'

export const About = () => (
    <div>
        <h1 className="text-info text-center">About Us</h1>
        <br/>
        <div className="container">
        <p>We are pleased to introduce ORNATE MARITIME PVT LTD (OMPL) established in 2019, located in Navi Mumbai (INDIA). The aim of our company is to provide a quality service, where our clients have total confidence in our ability to manage the human resources. We offer various crew management packages for e.g. – full crew management service and partial crew management service. In all cases we work very closely with a client maintaining direct contact so that solid relationship is built up.</p>
        </div>
        <br/>        
        <h2 className="text-info text-center">Our Team</h2>
        <br/>
        <div className="container">
        <p>OMPL is managed by a team of well qualified personnel, to ensure efficient and highest standards of service for manning all types and sizes of vessels, to all our valuable and esteemed Principals. Its fully equipped with well qualified and high professional service committed for on-board and off-shore industries.
Mr. AMIT SINHA – DIRECTOR (MASTER MARINER)
Captain who served the Maritime Industry since last 15 years. He is associated with us and is looking after all the technical areas of development for the Crew department, including selection of officers, with a determined sense of business, direction and foresight, he has steered OMPL safely through growth and expansion, providing high quality ship management services to ship owners around the world.</p>
        <br/>
        </div>

     
        <h2 className="text-info text-center">Mission</h2>
        <br/>
        <div className="container">
        <p>To conduct all business with integrity and an innovative approach to recruitment” ensuring you to reach the target in time and to your utmost satisfaction. Flexibility of resources can be scaled to match the requirements. As the anticipated requirements increase, we expand our resources to address the growing needs.</p>
          </div>
        <br/>
  
        <h2 className="text-info text-center">Vision</h2>
        <br/>
        <div className="container">
        <p>To become the largest provider Human resources to facilitate growth and prosperity of business in India. We believe in continued growth and increasing the value of our company and service by providing the opportunity for businesses to work with highly motivated individuals.</p>       

        </div>
           </div>
)
